@import '../styles/variables/colors';
@import '../styles/variables/borders';
@import '../styles/variables/component-sizes';

@form-width: 780px;

.PersonalDataPage-errorSection {
  color: @c24-color-red;
  text-align: center;
  font-size: 16px;
  width: 779px;
  margin-bottom: 15px;
}

.TariffHeaderBox {
  &.PersonalData-tariffHeaderBox {
    border-bottom: @c24-border-light-grey;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  }
}
