.ComparisonDetails {
  border-bottom: none;
  border-top: none;
}

.ComparisonDetails-feature {
  border-bottom: @c24-border-comparison-features;
  display: flex;
  position: relative;
  flex-direction: column;
}

.ComparisonDetails-featureDetails {
  display: flex;
  border-top: @c24-border-comparison-features;
  height: auto;
}

.ComparisonDetails-featureTitle {
  background-color: @c24-color-background-grey;
  position: relative;
  display: flex;
  flex: 1;
  font-size: 14px;
  justify-content: center;
  padding: 10px 0;
}

.ComparisonDetails-featureName {
  padding: 0 33px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.ComparisonDetails-tariffFeature {
  background-color: @c24-color-white;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: default;
  border-left: @c24-border-comparison-features;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  &:first-of-type {
    border-left: none;
  }

  > div > svg {
    margin-top: 6px;
  }

  @media @c24-wide {
    font-size: 12px;
  }
}

.ComparisonDetails-tooltip {
  position: absolute;
  top: 0;
  right: 10px;
  height: 20px;
  cursor: help;
  bottom: 0;
  margin: auto;
}

.ComparisonDetails-tooltipIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-question-mark;
  transition-duration: 200ms;
}

.hover {
  .ComparisonDetails-tooltipIcon {
    fill: @c24-color-icon-active !important;
  }
}

.ComparisonDetails-featureIcon {
  > svg {
    width: 20px;
    height: 20px;
  }
}

.ComparisonDetails-featureValue {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 10px 8px 8px;
  > p {
    margin: 0px;
    word-break: break-word;
    hyphens: auto;
  }
}

.ComparisonDetails-numValue {
  min-width: 52px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}
