.PaymentChips {
  margin-top: 7px;
}

.PaymentChips-suggestedChip {
  text-align: center;
  height: 31px;
  border-radius: 15px;
  display: inline-block;
  padding: 5px 22px;
  font-size: 16px;
  background-color: @c24-color-white;
  margin-right: 5px;
  cursor: pointer;
  user-select: none;
  border: 1px solid @c24-color-divider-grey;

  &.is-selected {
    background-color: @c24-color-dark-green-alpha-15;
    border: 1px solid @c24-color-dark-green;
  }
}

.PaymentChips-infoText {
  font-size: 13px;
}

.PaymentChips-additionalInfoText {
  font-size: 12px;
  color: @c24-color-black;
  margin-top: 3px;

  &.is-selected {
    color: @c24-color-dark-green;
  }
}

.PaymentChips-tooltip {
  top: 11px;
  .Tooltip {
    width: 227px;
    font-size: 11px;
    color: @c24-color-dark-grey;
  }
}

.PaymentChips-infoIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-nobel-grey;
  margin-left: 3px;
  cursor: pointer;
  &.is-selected {
    fill: @c24-color-dark-green;
  }
}

.PaymentChips-suggestionContainer {
  display: flex;
  align-items: center;
}
