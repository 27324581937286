.AppointmentBookingErrorModal-header {
  font-size: 16px;
  display: flex;
  margin-bottom: 10px;
  font-weight: 700;

  svg {
    height: 22px;
    width: 22px;
    margin-right: 10px;
  }
}

.AppointmentBookingErrorModal-ctaButton {
  margin-top: 18px;
  float: right;
}
