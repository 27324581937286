.BoosterMessage {
  display: flex;
  padding-top: 4px;
}

.BoosterMessage-icon {
  padding-top: 4px;
  margin-right: 10px;
  svg {
    fill: @c24-color-dark-green;
  }
}

.BoosterMessage-text {
  color: @c24-color-dark-green;
  font-size: 12px;
  align-self: center;
}

.BoosterMessage-withLeftMargin {
  margin-left: 20px;
  padding-top: 0px;
}
