@import '../../../styles/variables/colors';
@import '../../../styles/variables/backgrounds';

.ModalDialog-container {
  background: rgba(80, 80, 80, 0.85);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15000;
  outline: 0;
  overflow: hidden;
}

.ModalDialog {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  width: 600px;
  background-color: @c24-color-modal-dialog-background;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background-clip: padding-box;
  outline: 0;
  border-radius: 10px;
  max-height: 85%;
  overflow: scroll;
  margin: 10px 0px;
}

.ModalDialog-box {
  position: fixed;
  padding: 0 15px;
  padding-bottom: calc(env(safe-area-inset-bottom) + var(--safe-area-tap-bar-not-app) + 20px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.ModalDialog-header {
  border: none;
  padding: 25px 25px 0 25px;
  border-bottom: none;
  min-height: 16px;
}

.ModalDialog-headerTitle {
  color: @c24-color-headline;
  font-weight: 700;
  margin: 0;
  line-height: 1.4;
  text-align: left;
}

.ModalDialog-headerClose {
  z-index: 99;
  width: 20px;
  height: 20px;
  fill: white;
  cursor: pointer;
  pointer-events: auto;

  &.is-hidden {
    display: none;
  }
}

.ModalDialog-body {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 15px;
}
