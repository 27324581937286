.InlineValidation {
  position: relative;
  width: 100%;
  display: flex;
}

.InlineValidation-bar {
  position: absolute;
  height: 9px;
  width: 0;
  bottom: 0px;
  z-index: 35;
  background-color: transparent;
  box-sizing: border-box;
  transition-property: width, background-color;
  transition-duration: 0.3s, 0s;
  clip-path: inset(7px 0 0 0);
  border-radius: 5px;

  &.is-hidden {
    width: 0;
  }

  &.is-valid {
    background-color: @c24-color-dark-green;
    width: 100%;
  }
}
