.Booster {
  display: block;
  width: fit-content;
  font-size: 14px;
  border-radius: 15px;
  font-weight: bold;
  padding: 2px 8px 2px 8px;
  background-color: @c24-color-dark-green-alpha-15;
  color: @c24-color-dark-green;
}
