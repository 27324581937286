.ResultInsuranceFilterChip-insuranceChip {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px;
  background-color: @c24-color-white;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid @c24-color-light-grey;
  margin-right: 10px;
  position: relative;

  &.is-selected {
    background-color: @c24-color-light-blue;
  }
}

.ResultInsuranceFilterChip-logo {
  height: 20px;
  max-width: 100px;
  margin-right: 10px;

  &.is-not-available {
    filter: grayscale(1);
    cursor: default;
  }
}

.ResultInsuranceFilterChip-closeIcon {
  width: 10px;
  height: 10px;
  fill: @c24-color-dark-blue;
  margin-left: 10px;

  &.is-selected {
    margin-left: 0;
  }

  &.is-not-available {
    fill: @c24-color-dark-grey;
  }
}

.ResultInsuranceFilterChip-notAvailableOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: @c24-color-grey;
  opacity: 0.3;
}

.ResultInsuranceFilterChip-maxPension {
  font-size: 12px;
  color: @c24-color-grey;
  white-space: nowrap;
}
