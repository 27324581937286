.SummaryBox {
  padding: 0;
  pointer-events: all;
}

.SummaryBox-title {
  font-size: 20px;
  padding: 8px 15px;
  color: @c24-color-black;
}

.SummaryBox-body {
  padding: 8px 15px;
}
