body {
  --safe-area-tap-bar-not-app: 0px;
  display: block !important; // disable nextjs fouc

  &.disableScrolling {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}

.c24m-page-content {
  display: none;
}

.c24m-page {
  .OverlayContainer.is-opened {
    overflow: hidden;
  }
}

.c24m-footer {
  padding-bottom: env(safe-area-inset-bottom);
}

.c24m-footer.is-sticky {
  position: absolute;
  bottom: 0px;
}

.c24m-footer.is-elevated .c24m-footer-content {
  padding-bottom: 100px;
}

.c24m-page-content {
  &.has-grey-background {
    background-color: @c24-color-background-grey;
  }
}

.c24m-customer {
  &.c24m-customer-guest {
    &.is-hidden {
      display: none;
    }
  }

  &.c24m-customer-user {
    &.is-hidden {
      display: none;
    }
  }

  &.c24m-customer-expired {
    &.is-hidden {
      display: none;
    }
  }
}

#c24m-navi-customer-user,
#c24m-navi-customer-guest,
#c24m-navi-customer-expired {
  &.is-hidden {
    display: none;
  }
}

#c24m-navi-salutation-user,
#c24m-navi-salutation-guest,
#c24m-navi-salutation-expired {
  &.is-hidden {
    display: none;
  }
}

#c24m-kb-logout-navi-expired,
#c24m-kb-logout-navi-user {
  &.is-hidden {
    display: none;
  }
}

.c24m-search {
  &.is-hidden {
    display: none;
  }
}

#c24m-nav-slide {
  z-index: 37;
}

.c24-uli-loginlayer {
  background: @c24-color-white;
}
