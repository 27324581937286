.SelectBox {
  width: 100%;
  position: relative;
  border: @c24-border-input;
  border-radius: @c24-form-input-border-radius;
  font-size: @c24-form-input-font-size;
  padding: 0 35px 0 7px;
  color: @c24-color-input;
  background-repeat: no-repeat;
  background-color: @c24-color-input-background;
  background-size: 18px;
  background-position-x: calc(100% - 10px);
  background-position-y: 22px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  height: @c24-form-input-height;
  min-height: @c24-form-input-height;
  text-overflow: ellipsis;

  &:hover {
    border-bottom: @c24-border-input-hover;
  }

  &:focus,
  &:active {
    border: @c24-border-input-focus;
    background-position-y: -20px;
  }

  &.is-error {
    border: @c24-border-input-error;
    background-position-y: -66px;
  }

  &.is-disabled {
    background-color: @c24-color-disabled-background;
    color: @c24-color-input-disabled;
  }

  &.has-helptext {
    background-position-x: calc(100% - 45px);
    padding-right: 70px;
  }
}

.SelectBox-helptext {
  position: absolute;
  right: 0px;
  top: 0px;
  height: @c24-form-input-height;
}

.SelectBox-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: @c24-color-is-error;
  font-size: 12px;
}
