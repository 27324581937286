@import '../styles/variables/colors';

.Main {
  color: @c24-color-page-text;
  font-size: 16px;
  line-height: 1.428571429; // 20/14;
  text-size-adjust: 100%;
  background-color: @c24-color-white;

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &.has-grey-background {
    background-color: @c24-color-background-grey;
  }

  &.is-max-height {
    height: 100%;
  }
}

#__next {
  &.is-max-height {
    height: 100%;
  }
}

button {
  color: inherit;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;

  &::-moz-focus-inner {
    border: 0;
  }
}

svg {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
