.TariffReviews-EFeedbackContainer {
  padding: 15px;
  background-color: @c24-color-white;
}

.TariffReviews-EFeedbackHeader {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 20px;
}

.TariffReviews-trustFooterWithDisclaimer {
  background: @c24-color-white;
}
