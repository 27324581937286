.InvestmentTypeSelect-radioHeading {
  display: flex;
  align-items: center;

  > svg {
    height: 20px;
    width: 30px;
  }
}

.InvestmentTypeSelect-radioSvg {
  fill: @c24-color-malaga-grey;
}
