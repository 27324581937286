.ResultFilterOverlay {
  padding: 30px 10px;
  overflow-y: auto;
  overscroll-behavior: contain;
  height: 80%;
  padding-bottom: 20%;
}

.ResultFilterOverlay-OtherFiltersLabel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
