.OnboardingProductSelectionFeatureRow-feature {
  display: flex;
  border-bottom: none;
  flex-direction: column;
}

.OnboardingProductSelectionFeatureRow-featureValue {
  text-align: center;
  position: relative;
  flex: 1 1 0;
  padding: 5px;
  border-right: @c24-border-gainsboro-grey;
  justify-content: center;
  display: flex;

  &.is-centered {
    align-items: center;
  }

  &:last-child {
    border-right: none;
  }

  &.is-recommended {
    color: @c24-color-dark-green;
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.7;
    color: @c24-color-grey;
  }
}

.OnboardingProductSelectionFeatureRow-featureTitle {
  justify-content: center;
  background: @c24-color-background-grey;
  position: relative;
  padding: 10px 36px !important;
  text-align: center;
  align-items: center;

  &:last-child {
    border-right: none;
  }
}

.OnboardingProductSelectionFeatureRow-featureValuesCont {
  flex-direction: row;
  display: flex;
  background: @c24-color-white;
}
