.StickyButton-container {
  width: 100%;
  background: @c24-color-background-grey;
  position: fixed;
  left: 0;
  bottom: 0;
  transition: all 0.5s ease 0s;
  transform: translate3d(0, 0, 0);
  padding-bottom: calc(env(safe-area-inset-bottom));
  z-index: 100;
}

.StickyButton-button {
  margin: 10px;
  width: 95%;
}
