.EFeedbackRatingSelectableBar-container {
  display: flex;
  color: @c24-color-dark-blue;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 14px;

  &.is-active {
    color: @c24-color-yellow;
  }
}

.EFeedbackRatingSelectableBar-starsLabel {
  width: 60px;
}

.EFeedbackRatingSelectableBar-bar {
  flex: 1;
  margin-left: 5px;
  height: 10px;
  background-color: @c24-color-athens-gray;
  align-self: center;
  border-radius: 6px;
  display: flex;
}

.EFeedbackRatingSelectableBar-innerBarStart {
  background-color: @c24-color-catalina-blue;
  height: 100%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  overflow: hidden;

  &.is-active {
    background-color: @c24-color-yellow;
  }
}

.EFeedbackRatingSelectableBar-innerBarEnd {
  background-color: @c24-color-catalina-blue;
  height: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  max-width: calc(100% - 5px);

  &.is-active {
    background-color: @c24-color-yellow;
  }
}

.EFeedbackRatingSelectableBar-ratingCount {
  width: 40px;
  text-align: end;
}
