.EFeedbackRatingStars {
  width: 75px;
  position: relative;
  height: 15px;
}

.EFeedbackRatingStars-starsContainer {
  position: absolute;
  top: 0px;
  height: 15px;
  overflow: hidden;
  line-height: 15px;
}

.EFeedbackRatingStars-star {
  height: 15px;
  width: 15px;
  fill: @c24-color-gainsboro-grey;

  &.is-overlay {
    fill: @c24-color-light-orange;
  }
}
