.OnboardingProductSelectionTableRows-featureIcon {
  position: relative;
  top: 2px;

  > svg {
    width: 20px;
    height: 20px;
  }

  &.is-green > svg * {
    fill: @c24-color-dark-green;
  }
}
