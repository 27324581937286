.ResultFilterChips-selectedFiltersListContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;

  &.no-tariffs {
    margin-bottom: 20px;
  }
}

.ResultFilterChips-filterContainer {
  background-color: @c24-color-light-blue;
  border: @c24-border-divider-grey;
  font-size: 14px;
  margin: 10px 10px 0 0;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  transition-duration: 200ms;
  cursor: pointer;
  user-select: none;
}

.ResultFilterChips-unselectFilterButton {
  margin-left: 10px;
  width: 10px;
  height: 10px;
  fill: @c24-color-dark-blue;
}

.ResultFilterChips-unselectAllFiltersButton {
  font-size: 14px;
  margin: 10px;
  margin-bottom: 0;
  margin-left: 10px;
  white-space: nowrap;
  color: @c24-color-dark-blue;
  padding: 2px 2px 0 2px;
}

.ResultFilterChips-chipsList {
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
}
