.OnlineContractBox {
  background-color: @c24-color-white;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-bottom: 1px solid @c24-color-divider-grey;
}

.OnlineContractBox-title {
  font-size: 20px;
}

.OnlineContractBox-checkIcon {
  width: 24px;
  height: 20px;
  margin-right: 10px;
  fill: @c24-color-checked;
}

.OnlineContractBox-hint {
  display: flex;
  margin-top: 10px;
}

.OnlineContractBox-hints {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.OnlineContractBox-row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.OnlineContractBox-separator {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.OnlineContractBox-line {
  height: 1px;
  background: @c24-color-divider-grey;
  flex-grow: 1;
}

.OnlineContractBox-separatorText {
  margin: 0 20px;
  color: @c24-color-grey;
}

.OnlineContractBox-content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
