.ThankyouOnlineContractStep {
  background-color: @c24-color-thankyou-step-background;
  padding: 0px 13px 20px;

  &.is-appointment-booking-available {
    background-color: @c24-color-white;
    border-bottom: @c24-border-divider-grey;

    .ThankyouOnlineContractStep-header {
      padding-top: 20px;
    }
  }
}

.ThankyouOnlineContractStep-header {
  display: flex;
  margin-bottom: 15px;
  align-items: flex-start;
  padding-top: 12px;

  &.is-preferred {
    margin-bottom: 25px;
    .ThankyouOnlineContractStep-headerIcon > svg {
      * {
        fill: @c24-color-dark-green;
      }
    }
  }
}

.ThankyouOnlineContractStep-subheader {
  font-size: 16px;
  margin-bottom: 20px;
}

.ThankyouOnlineContractStep-booster {
  margin-top: 10px;
}

.ThankyouOnlineContractStep-headerIcon {
  margin-right: 10px;
  height: 20px;
  margin-top: 5px;

  > svg {
    width: 30px;

    * {
      fill: @c24-color-dark-blue;
    }
  }
}

.ThankyouOnlineContractStep-headerText {
  font-size: 20px;
  color: @c24-color-malaga-grey;
}

.ThankyouOnlineContractStep-hint {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 15px;
  color: @c24-color-dark-green;
}

.ThankyouOnlineContractStep-checkIcon {
  width: 20px;
  height: 16px;
  margin-right: 10px;
  align-items: center;
  fill: @c24-color-dark-green;
}

.ThankyouOnlineContractStep-inputRow {
  margin-bottom: 20px;
}

.ThankyouOnlineContractStep-label {
  font-size: @c24-form-input-label-font-size;
  color: @c24-form-input-label-color;
  margin-bottom: @c24-form-input-label-margin-bottom;
  align-items: center;
  display: flex;
  position: relative;

  &.has-helpText {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.ThankyouOnlineContractStep-errorSection {
  color: @c24-color-red;
  text-align: center;
  font-size: 20px;
  margin: 15px 40px 15px 0px;
}
