.DateInputField-wrapper {
  position: relative;
  width: 100%;
}

.DateInputField-input {
  width: 100%;
  height: @c24-form-input-height;
  min-height: @c24-form-input-height;
  color: @c24-color-input;
  border: @c24-border-input;
  border-radius: @c24-form-input-border-radius;
  font-size: @c24-form-input-font-size;
  font-weight: normal;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0 0 0 10px;

  &.is-active {
    border: @c24-border-input-focus;
  }

  &.is-error {
    border: @c24-border-input-error;
  }

  &.is-disabled {
    background-color: @c24-color-disabled-background;
    color: @c24-color-input-disabled;
  }
}
