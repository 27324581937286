.TariffDetailsTestReports {
  padding: 15px;
  background: @c24-color-white;
  margin-bottom: 15px;
}

.TariffDetailsTestReports-header {
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
}

.TariffDetailsTestReports-headerText {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 20px;
}

.TariffDetailsTestReports-report {
  display: flex;
  margin-top: 20px;
}

.TariffDetailsTestReports-logoContainer {
  margin-right: 20px;
  width: 71px;
  height: 86px;
}

.TariffDetailsTestReports-logo {
  width: 71px;
  height: 86px;
  object-fit: contain;
}

.TariffDetailsTestReports-date {
  color: @c24-color-grey;
  font-size: 14px;
}

.TariffDetailsTestReports-text {
  & p {
    margin: 0;
    font-size: 14px;
  }
}

.TariffDetailsTestReports-toggleButton {
  font-size: 14px;
  color: @c24-color-dark-blue;
  text-decoration: none;
  text-align: right;
  padding: 12px;

  &.is-disabled {
    color: @c24-color-grey;
  }
}

.TariffDetailsTestReports-toggleButtonIcon {
  width: 13px;
  height: 7px;
  fill: @c24-color-dark-blue;
  margin-left: 10px;
  rotate: 180deg;
  transition: all 0.3s ease-in-out;
  transition-delay: 150ms;

  &.is-up {
    rotate: 360deg;
  }

  &.is-disabled {
    fill: @c24-color-grey;
  }
}
