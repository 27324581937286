.ResultInsuranceFilter-chipsList {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: scroll;
  justify-content: space-between;
  margin-left: 0px;
  padding-top: 10px;

  &.has-bottom-margin {
    margin-bottom: 5px;
  }
}
