.ResultFilterHeader {
  display: flex;
  height: 45px;
  background: @c24-color-white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  position: sticky;
  top: -1px;
  z-index: 100;
  overflow: hidden;
  flex-flow: nowrap;
  flex-direction: column;
}

.ResultFilterHeader-buttonsCont {
  display: flex;
  width: 100%;
  height: 45px;
  flex-shrink: 0;
}

.ResultFilterHeader-comparisonBar {
  position: relative;
  display: flex;
  width: 100%;
  height: 45px;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-shrink: 0;
}

.ResultFilterHeader-closeIcon {
  width: 16px;
  height: 16px;
  fill: @c24-color-dark-blue;
}

.ResultFilterHeader-tab {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid @c24-color-divider-grey;
  margin-top: 10px;
  margin-bottom: 10px;

  &:last-child {
    border-right: none;
  }
}

.ResultFilterHeader-comparisonReset {
  color: @c24-color-grey;
  font-size: 16px;

  &.is-active {
    color: @c24-color-dark-blue;
  }
}

.ResultFilterHeader-comparisonBarTitle {
  font-size: 18px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  line-height: 45px;
  pointer-events: none;
}

.ResultFilterHeader-tabTextWrapper {
  display: flex;
  flex-direction: column;
}

.ResultFilterHeader-sortIconWrapper {
  position: relative;
  width: 28px;
}

.ResultFilterHeader-sortIcon {
  position: absolute;
  bottom: -9px;
  height: 20px;
  width: 20px;

  g,
  path {
    fill: @c24-color-black;
  }
}

.ResultFilterHeader-filterIconWrapper {
  position: relative;
  width: 28px;
}

.ResultFilterHeader-filterIcon {
  position: absolute;
  bottom: -9px;
  height: 20px;
  width: 20px;

  g,
  path {
    fill: @c24-color-black;
  }
}

svg.ResultFilterHeader-vergleichenIcon {
  margin-right: 7px;
  height: 18px;
  width: 18px;

  g,
  path {
    fill: @c24-color-black;
  }
}

.ResultFilterHeader-redDotIcon {
  position: absolute;
  width: 8px;
  height: 8px;
  fill: #e30613;
  bottom: 5px;

  &--filter {
    right: 5px;
  }

  &--sort {
    right: 7px;
  }
}

span.ResultFilterHeader-tabText {
  font-size: 14px;
  color: @c24-color-black;
}

.ResultFilterHeader-selectedFilterText {
  font-size: 12px;
  color: @c24-color-black;
  line-height: 10px;
  padding-bottom: 5px;
}
