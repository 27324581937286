.TariffDetailsTariffNote {
  padding: 15px;
  background: @c24-color-white;
  margin-bottom: 15px;
}

.TariffDetailsTariffNote-header {
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
}

.TariffDetailsTariffNote-headerText {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 20px;
}

.TariffDetailsTariffNote-gradeContainer {
  display: flex;
  margin-top: 12px;
}

.TariffDetailsTariffNote-text {
  font-size: 16px;
  margin: 0;
  width: 70%;
}

.TariffDetailsTariffNote-grade {
  position: relative;
  height: 76px;
  width: 71px;
  float: right;
  cursor: default;
  margin-right: 20px;
}

.TariffDetailsTariffNote-gradeIcon {
  height: 76px;
  width: 71px;
  background-repeat: no-repeat;
  background-size: 71px 76px;
  background-image: url(/grey-grade.svg);
  display: block;

  &.is-golden {
    background-image: url(/gold-grade.svg);
  }
}

.TariffDetailsTariffNote-gradeValue {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 27px;
  font-size: 22px;
  font-weight: 700;
}

.TariffDetailsTariffNote-gradeSubText {
  position: absolute;
  top: 15px;
  width: 100%;
  text-align: center;
  font-size: 10px;
}

.TariffDetailsTariffNote-gradeText {
  position: absolute;
  font-size: 11px;
  top: 59px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  color: @c24-color-grade-text;

  &.is-bold {
    font-weight: 700;
  }
}
