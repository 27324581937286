.WishListHeart-heartSvg {
  height: 20px;
  width: 22px;
  cursor: pointer;

  &.is-empty {
    fill: @c24-color-black;
  }

  &.is-filled {
    path {
      fill: @c24-color-red;
    }
  }

  &.is-active {
    animation: heart 0.3s ease;
  }
}

.WishListHeart-heartContainer {
  display: flex;
  align-items: center;
}

@keyframes heart {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  30% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  60% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
