.EFeedbackCustomerReviews {
  margin-top: 10px;
}

.EFeedbackCustomerReviews-header {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 16px;
}

.EFeedbackCustomerReviews-comment {
  margin-top: 10px;
  border-top: 1px solid @c24-color-gainsboro-grey;
}

.EFeedbackCustomerReviews-ratingHeader {
  padding-top: 6px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.EFeedbackCustomerReviews-ratingHeaderName {
  padding-left: 8px;
  padding-top: 2px;
}

.EFeedbackCustomerReviews-ratingHeaderLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.EFeedbackCustomerReviews-ratingDate {
  padding-top: 2px;
}

.EFeedbackCustomerReviews-commentText {
  margin-top: 8px;
  margin-bottom: 10px;
  font-size: 14px;
}

.EFeedbackCustomerReviews-thumbsUpWrapper {
  display: flex;
  justify-content: flex-end;
}

.EFeedbackCustomerReviews-thumbsUp {
  display: flex;
  font-size: 14px;
  color: @c24-color-dark-blue;
  cursor: pointer;
  align-items: center;
}

.EFeedbackCustomerReviews-thumbsUpSvg {
  display: flex;
  width: 18px;
  height: 18px;
  margin: -3px 4px 0px 4px;
  fill: @c24-color-dark-blue;
}

.EFeedbackComments-prevButton {
  padding: 0;
  margin-right: 15px;

  &:disabled {
    color: @c24-color-light-grey;
  }

  &:before {
    content: '\00AB';
    margin-right: 2px;
  }
}

.EFeedbackComments-nextButton {
  padding: 0;

  &:disabled {
    color: @c24-color-light-grey;
  }

  &:after {
    content: '\00BB';
    margin-left: 4px;
  }
}

.EFeedbackComments-navigationContainer {
  display: flex;
  justify-content: flex-end;
  color: @c24-color-dark-blue;
  font-size: 14px;
  margin-top: 24px;
}

.EFeedbackCustomerReviews-noComments {
  color: @c24-color-light-grey;
}

.EFeedbackCustomerReviews-loadingSpinner {
  display: flex;
  justify-content: center;
}
