.ProgressBar {
  display: flex;
  align-items: center;
}

.ProgressBar-bar {
  background-color: @c24-color-light-nobel-grey;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 20px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(
    white,
    black
  ); // fixes safari + border-radius + overflow:hidden + transition in child issue
}

.ProgressBar-progress {
  background: @c24-color-catalina-blue;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  transform: translate3d(-99%, 0, 0);
}

.ProgressBar-percent {
  width: 40px;
  margin-left: 10px;
  float: left;
  color: @c24-color-catalina-blue;
  text-align: left;
  font-size: 16px;
  min-width: 40px;
}
