.TariffFeatureLegend {
  background-color: @c24-color-white;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.TariffFeatureLegend-item {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.TariffFeatureLegend-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.TariffFeatureLegend-text {
  font-size: 14px;
  margin: 0;
}

.TariffFeatureLegend-content {
  display: flex;
  align-items: center;
  padding: 11px 0;
}
