@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/borders';

.RadioButton {
  text-align: left;
  display: block;
  font-size: 18px;
  outline: none;
  box-shadow: none;
  color: @c24-color-input;
  padding: 0;
  background: @c24-color-white;
  border-bottom: @c24-border-light-grey;
  margin: 0 10px;

  &.is-active {
    color: @c24-color-radio-highlight;
  }

  &:hover,
  :focus {
    color: @c24-color-radio-highlight;
  }

  &:last-child {
    border-bottom: none;
  }
}

.RadioButton-option {
  display: flex;
  position: relative;
  align-items: center;

  padding: 15px 0;
  padding-left: 36px;
  position: relative;

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 18px;
    height: 18px;
    border-radius: 10px;
    border: @c24-border-input;
    top: 17px;
    left: 6px;
    transition: 0.3s border-color;
    border-color: @c24-color-nobel-grey;
    background-color: @c24-color-white;
  }

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 8px;
    height: 8px;
    border-radius: 4px;
    top: 22px;
    left: 11px;
    background-color: @c24-color-dark-blue;
    opacity: 0;
  }

  &.is-selected:after {
    opacity: 1;
  }

  &.is-selected:before {
    border-color: @c24-color-dark-blue;
  }

  &.is-error:before {
    border-color: @c24-color-is-error;
  }

  &.is-error:after {
    border-color: @c24-color-is-error;
  }
}
