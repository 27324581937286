@import '../../../styles/variables/colors';
@import '../../../styles/variables/borders';
@import '../../../styles/variables/backgrounds';

.FormInput {
  position: relative;
  width: 100%;

  &.is-currency {
    &:after {
      position: absolute;
      top: 13px;
      font-size: @c24-form-input-font-size;
      content: '€';
      right: 10px;
    }
  }

  &.has-helpText {
    .FormInput-field {
      padding-right: 35px;
    }

    &.is-currency {
      .FormInput-field {
        padding-right: 60px;
      }
      &:after {
        right: 45px;
      }
    }
  }
}

.FormInput input {
  padding: 0 0 0 10px;
}

.FormInput-field {
  width: 100%;
  height: @c24-form-input-height;
  min-height: @c24-form-input-height;
  color: @c24-color-input;
  border: @c24-border-input;
  border-radius: @c24-form-input-border-radius;
  font-size: @c24-form-input-font-size;
  font-weight: normal;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  box-sizing: border-box;

  &.is-active {
    border: @c24-border-input-focus;
  }

  &.has-overlay {
    padding-left: 33px;
  }

  &.is-error {
    border: @c24-border-input-error;
  }

  &.is-disabled {
    background-color: @c24-color-disabled-background;
    color: @c24-color-input-disabled;
    opacity: 1;
  }
}

.FormInput-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: @c24-color-is-error;
  font-size: 12px;
}

.FormInput-info {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 5px 0 0 0;
  color: @c24-color-link;
  font-size: 12px;
}

.FormInput-helpText {
  position: absolute;
  top: 0px;
  right: 0px;
  height: @c24-form-input-height;
}
