@import '../../../styles/variables/colors';

.EkomiLogo {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
  width: 75%;
}

.EkomiLogo-svg {
  height: 100%;
  cursor: pointer;
  pointer-events: auto;
  width: 100%;
}

.EkomiLogo-header {
  font-weight: 700;
  color: @c24-color-dark-grey;
  margin-bottom: 15px;
}

.EkomiLogo-tooltipSubHeadline {
  font-weight: bold;
  color: @c24-color-headline;
}

.EkomiLogo-tooltipAddressHeadline {
  margin: 8px 0;
}

.EkomiLogo-tooltipContent {
  color: @c24-color-popup-content;
}

.EkomiLogo-tooltip .Tooltip {
  max-width: 475px;
}
