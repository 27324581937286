@import '../../../styles/variables/colors.less';

.TariffHeaderBox {
  position: relative;
  background-color: @c24-color-white;
  padding: 15px;
  margin-bottom: 15px;

  &.is-resultZero {
    padding-top: 34px;
  }
}

.TariffHeaderBox-row {
  display: flex;
}

.TariffHeaderBox-selectButton {
  margin-top: 8px;
}

.TariffHeaderBox-left {
  flex: 30;
  width: 200px;
  margin-right: 10px;
}

.TariffHeaderBox-center {
  flex: 40;
}

.TariffHeaderBox-right {
  flex: 30;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.TariffHeaderBox-insuranceLogo {
  max-width: 180px;
  max-height: 80px;
}

.TariffHeaderBox-tariffName {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  white-space: normal;
  overflow-wrap: break-word;
  max-width: 140px;
}

.TariffHeaderBox-possiblePrice {
  font-size: 24px;
  font-weight: bold;
  color: @c24-color-price;
}

.TariffHeaderBox-possiblePriceText {
  color: @c24-color-price;
  font-size: 12px;
  text-align: right;
}

.TariffHeaderBox-guaranteedPrice {
  font-size: 20px;
  font-weight: bold;
  color: @c24-color-black;
  margin-top: 7px;
  text-align: right;
}

.TariffHeaderBox-priceDescription {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.TariffHeaderBox-infoSvgIcon {
  height: 12px;
  width: 12px;
  pointer-events: auto;
  cursor: help;
  fill: @c24-color-nobel-grey;
}

.TariffHeaderBox-priceText {
  font-size: 12px;
  text-align: right;
}

.TariffHeaderBox-centerItem {
  margin-bottom: 5px;
  display: flex;
}

.TariffHeaderBox-centerItemLabel {
  width: 130px;
  display: inline-block;
}

.TariffHeaderBox-centerItemValue {
  font-weight: bold;
  margin-left: 5px;
  width: 170px;
}

.TariffHeaderBox-gradeContainer {
  margin-right: 65px;
  margin-left: 35px;
}

.TariffHeaderBox-headerIcons {
  display: flex;
}

.TariffHeaderBox-headerShareIcon {
  margin-left: 24px;
}
