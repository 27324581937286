.ComparisonPage-detailsButton {
  margin-top: 8px;

  .Button {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  &.is-centered {
    justify-content: center;
  }

  &.is-footer {
    padding: 16px 16px 16px;
    border-bottom: none;
  }

  &.no-border {
    border-bottom: none;
  }

  &.is-large {
    min-height: 110px;
  }
}

.ComparisonPage-spacer {
  background-color: @c24-color-comparison-spacer-background;
  flex: 1;
  padding: 0 14px;
}

.ComparisonPage-backButton {
  line-height: 28px;
  height: 32px;
  width: 90%;
  margin-top: 5px;
  margin-bottom: 15px;
}

.ComparisonPage-stickyContainer {
  position: relative;
}

.ComparisonPage-infoFooter {
  display: none;
}

.ComparisonPage-legend {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  font-size: 12px;
  justify-content: space-around;
}

.ComparisonPage-legendIcon {
  width: 18px;
  height: 18px;

  margin-right: 5px;

  &:first-child {
    margin-left: 0;
  }
}

.ComparisonPage-legendItemCont {
  display: flex;
  align-items: center;
}

.ComparisonPage-detailsButton {
  width: 100%;
}
