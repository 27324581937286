.TariffListOverlayProgressBar-headerText {
  font-size: 16px;
  @media @small-phone {
    font-size: 15px;
  }
  margin-top: 0;
  margin-bottom: 10px;
  color: @c24-color-black;
}

.TariffListOverlayProgressBar-text {
  font-size: 16px;
  @media @small-phone {
    font-size: 15px;
  }
  margin-top: 23px;
  margin-bottom: 2px;
  color: @c24-color-black;
}

.TariffListOverlayProgressBar-footer {
  display: flex;
  flex-direction: column;
}

.TariffListOverlayProgressBar-footerContainer {
  display: flex;
  justify-content: space-between;

  .TariffListOverlayProgressBar-logoContainer:last-child {
    margin-right: 0;
  }
}

.TariffListOverlayProgressBar-logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TariffListOverlayProgressBar-logo {
  height: auto;
  width: 65px;
  @media @small-phone {
    width: 50px;
  }
  @media @medium-phone {
    width: 55px;
  }
}

.TariffListOverlayProgressBar-logoText {
  font-size: 10px;
  color: @c24-color-black;
  text-align: center;
  margin: 0;
}
