.Overlay-container {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: #f4f4f4;
  z-index: 10200;
  top: 100%;
  transition-duration: 300ms;

  &.is-comparison {
    overflow-y: scroll !important;
  }

  &.is-scroll-enabled {
    @media screen and (max-width: 415px) {
      overflow-y: scroll !important;
    }
  }

  .StickyButton-container {
    transform: translate3d(0, 200px, 0);
  }
}

.Overlay-topBar {
  height: 45px;
  background: @c24-color-white;
  border-bottom: solid 1px @c24-color-light-grey;
  align-content: center;
  display: flex;
}

.Overlay-closeButton {
  height: 16px !important;
  width: 16px;
  margin: 15px 10px;
  fill: @c24-color-malaga-dark-blue;
}

.Overlay-title {
  margin: auto -15% auto auto;
  text-align: center;
  font-size: 18px;
}

.Overlay-resetButton {
  margin: auto 10px auto auto;
  font-size: 16px;
  color: @c24-color-dark-blue;

  &.is-default {
    color: @c24-color-grey;
  }
}

.Overlay-container.is-opened {
  transition-duration: 300ms;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;

  .Overlay-topBar {
    top: 0vh;
    position: sticky;
    z-index: 100;
  }

  .StickyButton-container {
    transform: translate3d(0, 0, 0);
    transition-duration: 300ms;
  }
}
