@import '../styles/variables/colors.less';

.ErrorPage {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ErrorPage-innerContainer {
  margin: 25px 15px 20px 15px;
}

.ErrorPage-header {
  font-size: 16px;
  color: @c24-color-headline;
  margin: 0;
  margin-bottom: 15px;
}

.ErrorPage-text {
  font-size: 14px;
  color: @c24-color-dark-grey;
  margin-bottom: 40px;
}

.ErrorPage-image {
  display: block;
  max-width: 100%;
  height: auto;
}

.ErrorPage-spinner {
  text-align: center;
}
