.AppointmentBookingToolDays-container {
  border-radius: 3px;
  background-color: @c24-color-soft-orange;
  padding: 11px 13px 13px 13px;
}

.AppointmentBookingToolDays-title {
  font-size: 16px;
  margin-bottom: 6px;
}

.AppointmentBookingToolDays-wrapper {
  display: flex;
  justify-content: space-between;
}

.AppointmentBookingToolDays-button {
  text-align: center;
  background-color: @c24-color-white;
  border-radius: 3px;
  border: 1px solid @c24-color-nobel-grey;
  width: 50px;
  height: 40px;

  &.is-selected {
    border: none;
    background-color: @c24-color-dark-blue;
    color: @c24-color-white;
  }
}

.AppointmentBookingToolDays-date {
  font-size: 13px;
}

.AppointmentBookingToolDays-name {
  font-size: 14px;
  font-weight: bold;
}
