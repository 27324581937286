.YearEndBanner {
  display: flex;
  align-items: center;
}

.YearEndBanner-banner {
  background-color: @c24-color-gold;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.YearEndBanner-contentBox {
  -webkit-text-size-adjust: none; // disable font resize in safari
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: @c24-color-white;
}

.YearEndBanner-content {
  width: 100%;
}
