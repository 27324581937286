.LifelongPensionBox {
  background-color: @c24-color-background-light-green;
  padding: 8px 15px;
  color: @c24-color-black;
  font-size: 14px;
  margin: 13px 10px 14px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LifelongPensionBox-title {
  font-size: 14px;
  margin: 0px;
  font-weight: bold;
}

.LifelongPensionBox-description {
  font-size: 14px;
  line-height: 17px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.LifelongPensionBox-tooltip {
  margin-bottom: 6px;
}

.LifelongPensionBox-questionMarkIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-nobel-grey;
}
