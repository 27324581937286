.NoTariffsFound {
  margin-top: 25px;
  padding: 0 10px;
}

.NoTariffsFound-headline {
  color: @c24-color-noTariffsFound-headline;
  font-weight: 700;
  font-size: 16px;
}

.NoTariffsFound-text {
  margin-top: 20px;
  font-size: 14px;
  color: @c24-color-noTariffsFound-text;
}

.NoTariffsFound-contact {
  margin-top: 5px;
  color: @c24-color-contact;
  font-weight: 700;
}

.NoTariffsFound-phone {
  font-size: 18px;
}

.NoTariffsFound-email {
  margin-top: 10px;
}
