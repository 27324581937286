.PersonalDataPageAgreementSection {
  padding: 0 13px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 18px;
}

.PersonalDataPageAgreementSection-link {
  text-decoration: underline;
}

.PersonalDataPageAgreementSection-newTerms {
  border-bottom: @c24-border-light-grey;
  padding-bottom: 20px;
}
