@import '../../styles/variables/colors';

.ResultTariffList {
  min-height: 400px;
}

.ResultTariffList-singleRow,
.ResultTariffList-singleRowResultZero {
  position: relative;
  margin: 0px 10px 15px;
}

.ResultTariffList-singleRowResultZero {
  &:last-of-type {
    margin-bottom: 0px;
  }
}

.ResultTariffList-campaignRow {
  position: relative;
  margin: 0px 10px 15px;
}

.ResultTariffList-comparisonHintBox {
  height: 50px;
  width: 100%;
  background-color: @c24-color-pale-green;
  background-size: cover;
  color: @c24-color-dark-blue;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 15px;
  justify-content: space-between;
  cursor: pointer;

  @media @c24-tablet-l-wide {
    width: 100%;
    white-space: nowrap;
    font-size: 15px;
  }

  @media @c24-tablet-m-wide {
    font-size: 14px;
  }

  @media @c24-tablet-s-wide {
    font-size: 12px;
  }
}

.ResultTariffList-noTariffsCarouselWrapper {
  margin: 0px 10px;
}

.ResultTariffList-comparisonHint {
  font-weight: 700;
}

.ResultTariffList-comparisonIcon {
  transform: scaleY(-1);
  color: @c24-color-dark-blue;
  width: 20px;
  height: 18px;
  margin-right: 10px;
}

.ResultTariffList-leftContainer {
  display: flex;
}

.ResultTariffList-comparisonHintButton {
  width: 150px;
  height: 23px;
  font-family: Verdana, serif;
  font-size: 14px;
  color: #005ea8;
  font-weight: 400;
  text-decoration: none solid rgb(0, 94, 168);
  text-align: right;
}

.ResultTariffList-comparisonSvg {
  transform: rotate(90deg);
  height: 15px;
  margin-right: 15px;
  vertical-align: text-bottom;

  @media @c24-tablet-m-wide {
    margin-right: 5px;
  }
}

.ResultTariffList-gradesExplanation {
  padding: 0px 12px;
  font-size: 12px;
}

.ResultTariffList-campaignBanners {
  border-radius: 3px;
  overflow: hidden;
  margin-top: 15px;
}

.ResultTariffList-CheckIcon {
  vertical-align: text-bottom;
  width: 19px;
  min-width: 19px;
  height: 16px;
  margin-top: 2px;
  margin-right: 10px;
  fill: @c24-color-green;
}

.ResultTariffList-riesterMessage {
  display: flex;
  border: solid @c24-color-dark-green 1px;
  border-radius: 3px;
  background-color: #e5f2e5;
  margin: 15px 10px;
  font-size: 15px;
  padding-right: 10px;
  padding-left: 15px;
}
.ResultTariffList-riesterMessageHeadline {
  margin-bottom: 5px;
}
.ResultTariffList-riesterMessageList {
  padding-left: 0px;
  color: @c24-color-black;
  margin-top: 0px;
}
.ResultTariffList-riesterMessageListItem {
  display: flex;
  list-style-type: none;
}
.ResultTariffList-riesterMessageListItemText {
  padding: 0px;
  margin: 0px;
}

.ResultTariffList-resultZeroWrapper {
  background-color: @c24-color-light-blue;
  padding-bottom: 9px;
  padding-top: 9px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}
