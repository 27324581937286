.ResultSecondaryComparisonButton {
  display: flex;
  background-color: @c24-color-light-blue;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 12%);
  border-radius: 3px;
  align-items: center;
  padding: 10px;
  margin-top: 15px;
  outline: none;
}
.ResultSecondaryComparisonButton-compareIcon {
  height: 35px;
  width: 35px;
  margin-right: 10px;
  g,
  path {
    fill: #005ea8;
  }
}
.ResultSecondaryComparisonButton-buttonText {
  color: #005ea8;
  font-size: 16px;
  line-height: 19px;
}

.ResultSecondaryComparisonButton-link {
  text-decoration: underline;
  font-weight: bold;
}
