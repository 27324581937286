@import '../../styles/variables/colors';
@import '../../styles/variables/borders';

.ResultTariffListItemBullets-tooltipAnchor {
  position: absolute;
  left: 30px;
  top: 5px;
}

.ResultTariffListItemBullets-container {
  flex: 1;
  min-width: 40%;
  font-size: 16px;
  margin-bottom: 4px;
  margin-right: 10px;
}

.ResultTariffListItemBullets-feature {
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.ResultTariffListItemBullets-feature {
  &.is-last {
    margin-bottom: 10px;
  }

  &.is-detail {
    .ResultTariffListItemBullets-featureText {
      font-size: 16px;
    }
  }
}

.ResultTariffListItemBullets-featureIcon {
  margin-right: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1px;

  > svg {
    width: 20px;
    height: 20px;
  }
}

.ResultTariffListItemBullets-helpText {
  &:not(:last-of-type) {
    border-bottom: @c24-border-divider-grey;
    margin-bottom: 15px;
  }
  font-size: 14px;
  padding: 10px 25px 13px;
}
