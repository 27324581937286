.ComparisonNavigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: @c24-color-icon-default;
  user-select: none;
  background: @c24-color-white;
  padding: 11px 10px;
}

.ComparisonNavigation-backButton {
  display: flex;
  align-items: center;
}

.ComparisonNavigation-backButtonArrow {
  width: 23px;
  height: 13px;
  transform: rotate(270deg);
  margin-right: 5px;
  margin-left: -5px;

  * {
    fill: @c24-color-icon-default;
  }
}
