@import '../../styles/variables/colors';

.ThankyouInfo {
  padding: 0px 13px 20px;
  background-color: @c24-color-white-background;
}

.ThankyouInfo-headline {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ThankyouInfo-checkSvg {
  width: 28px;
  min-width: 28px;
  height: 22px;
  margin-right: 15px;
  margin-top: 5px;
  fill: @c24-color-dark-green;
}

.ThankyouInfo-headlineText {
  color: @c24-color-dark-green;
  font-size: 20px;
  font-weight: normal;
  margin: 0px;
}

.ThankyouInfo-emailConfirmation {
  margin-bottom: 16px;
  b {
    word-break: break-all;
  }
}

.ThankyouInfo-tariffInfoRow {
  display: flex;
  margin-bottom: 4px;

  &:last-of-type {
    margin-bottom: 0px;
  }
}

.ThankyouInfo-tariffInfoLabel {
  min-width: 125px;
}

.ThankyouInfo-tariffInfoDescription {
  font-weight: 700;
  word-break: break-word;
  margin-left: 5px;
}

.ThankyouInfo-insuredPersonEmail {
  display: block;
}
