.DateInput {
  display: flex;
}

.DateInputFieldLabel {
  min-width: 30%;
  padding-right: 10px;

  &:last-child {
    min-width: 40%;
    padding-right: 0;
  }
}

.DateInput-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: @c24-color-is-error;
  font-size: 12px;
}
