.TariffDetailsPointsBox {
  padding: 15px;
  background: @c24-color-white;
  margin-bottom: 15px;
}

.TariffDetailsPointsBox-header {
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
}

.TariffDetailsPointsBox-headerText {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 20px;
}

.TariffDetailsPointsBox-description {
  font-size: 14px;

  & h3 {
    font-size: 14px;
    margin: 0px;
    display: inline-block;
  }
}
