.TariffFeaturesCategoriesList-category {
  background-color: @c24-color-white;
  padding: 15px;
  margin-bottom: 15px;
}

.TariffFeaturesCategoriesList-categoryTitle {
  margin: 0;
  padding-bottom: 14px;
  font-size: 16px;
}

.TariffFeaturesCategoriesList-featuresListContainer {
  padding-top: 18px;
  border-top: 1px solid @c24-color-light-nobel-grey;
}
