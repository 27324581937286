.AppointmentBookingConfirmation-container {
  padding: 0px 13px;
  padding-top: 20px;
  background-color: @c24-color-white;
}

.AppointmentBookingTool-confirmationMessageBox {
  display: flex;
  align-items: flex-start;
}

.AppointmentBookingConfirmation-checkSvg {
  width: 28px;
  height: 22px;
  fill: @c24-color-dark-green;
  margin-right: 10px;
}

.AppointmentBookingConfirmation-confirmationMessageBox {
  color: @c24-color-dark-green;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.AppointmentBookingConfirmation-confirmationMessage {
  color: @c24-color-dark-green;
  font-size: 20px;
}

.AppointmentBookingConfirmation-emailConfirmationInfo {
  font-size: 16px;
  word-break: break-word;
}

.AppointmentBookingConfirmation-appointmentDateDetailBox {
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.AppointmentBookingConfirmation-appointmentDateDetail {
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
}

.AppointmentBookingConfirmation-calendarSvg {
  width: 26px;
  height: 26px;
  fill: @c24-color-dark-blue;
  margin-right: 14px;
}
