@import '../../styles/variables/colors';
@import '../../styles/variables/backgrounds';

.ThankyouSteps {
  padding: 20px 28px 20px 13px;
  &.is-grey {
    background-color: @c24-color-thankyou-step-background;
  }
  background-color: @c24-color-white;
}

.ThankyouSteps-divider {
  border-bottom: 1px solid @c24-color-malaga-grey;
  margin-bottom: -9px;
  margin-top: 9px;
}

.ThankyouSteps-headline {
  color: @c24-color-black;
  font-size: 20px;
  margin-bottom: 20px;
}

.ThankyouSteps-numberSvg {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.ThankyouSteps-item {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ThankyouSteps-stepHeader {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.ThankyouSteps-headerText {
  font-weight: 700;
}

.ThankyouSteps-stepText {
  margin-left: 30px;
}
