.GradeFeatureList-header {
  padding: 15px;
  background-color: @c24-background-grade-features;
}

.GradeFeatureList-headerTitle {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}

.GradeFeatureList-headerContent {
  display: flex;
  align-items: center;
}

.GradeFeatureList-contentText {
  width: 76%;
}

.GradeFeatureList-points {
  width: 24%;
  text-align: right;
  margin-right: 10px;
}

@-moz-document url-prefix() {
  .GradeFeatureList-grade {
    padding-top: 1px !important;
  }
}

.GradeFeatureList-grade {
  text-align: center;
  padding: 2px;
  width: 29px;
  height: 20px;
  font-weight: 700;
  color: @c24-color-grade-text;
  float: right;
  background-color: @c24-background-grade-bad;

  &.is-good {
    background-color: @c24-background-grade-good;
  }

  &.is-veryGood {
    background-color: @c24-background-grade-very-good;
  }

  &.is-bad {
    background-color: @c24-background-grade-bad;
  }
}

.GradeFeatureList-content {
  background-color: @c24-background-grade-feature-list;
}

.GradeFeatureList-listItem {
  padding: 5px 0;
  margin: 0 15px;
  display: flex;
  align-items: center;
  border-bottom: @c24-border-default;

  &.is-last {
    border-bottom: none;
  }
}

.GradeFeatureList-title {
  font-weight: 700;
  width: 80%;
}

.GradeFeatureList-footer {
  display: flex;
  justify-content: center;
  padding: 16px 10px;
  background-color: @c24-background-grade-features;
}

.GradeFeatureList-footerItem {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.GradeFeatureList-footerColor {
  width: 19px;
  height: 15px;

  &--green {
    background-color: @c24-background-grade-very-good;
  }

  &--yellow {
    background-color: @c24-background-grade-good;
  }

  &--red {
    background-color: @c24-background-grade-bad;
  }
}
.GradeFeatureList-footerText {
  margin-left: 5px;
}
.GradeFeatureList-tooltip {
  .Tooltip {
    width: 450px;
    border: 1px solid #ccc;
    cursor: default;
    padding: 0;
    background: transparent;
    line-height: inherit;
  }

  .Tooltip-connector {
    max-height: 120%;
  }

  .Tooltip-arrow {
    background: @c24-color-background-grey;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
  }
}
