.ResultRankingBox {
  display: flex;
  background-color: @c24-color-divider-grey;
  padding: 8px 15px;
  margin: 7px 10px 10px;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  border-radius: 3px;
  line-height: 17px;
}

.ResultRankingBox-closeButton {
  padding: 0;
  margin: 2px 0px 0px 12px;
  display: flex;
  align-items: center;
}

.ResultRankingBox-closeIcon {
  height: 14px;
  width: 14px;
  * {
    fill: @c24-color-nobel-grey;
  }
}
