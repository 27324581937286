.PersonalDataSection-addressInputs {
  .PersonalDataSection-input;

  > div:last-of-type {
    flex: 2;
    margin-right: 10px;
  }
}

.PersonalDataSection-postalCodeFormInput {
  width: 150px;
  flex-basis: 0;
}

.PersonalDataSection-Street-input {
  .PersonalDataSection-input;

  > div:first-of-type {
    flex: 2;
    margin-right: 10px;
  }
  > div:last-of-type {
    flex: 1;
    margin-right: 10px;
  }
}

.PersonalDataAddressCities-selectBox {
  &.show-asLabel {
    border: none;
    cursor: auto;
    background: none;
    color: @c24-color-input;
  }
}

.PersonDataAddress-postalCodeAndCityRow {
  padding-bottom: 14px;
}
