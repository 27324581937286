.OnlineContractEnabledCheck {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OnlineContractEnabledCheck-button {
  border: 1px solid #dcdcdc;
  font-size: 14px;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  background-color: @c24-color-white;
  display: flex;
  align-items: center;
  width: 150px;
  transition-duration: 200ms;
  overflow: hidden;

  &.is-active {
    width: 188px;
    background-color: @c24-color-light-blue;
  }
}

.OnlineContractEnabledCheck-closeIcon {
  width: 16px;
  height: 15px;
  margin-left: 10px;
  fill: @c24-color-dark-blue;
}

.OnlineContractEnabledCheck-text {
  white-space: nowrap;
  flex: 1 1 0;
}

.OnlineContractEnabledCheck-tooltipIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-question-mark;
  transition-duration: 200ms;
  vertical-align: top;
}
