.InquiryButton-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: normal;
}

.InquiryButton-hint {
  color: @c24-color-button-hint;
  display: flex;
  margin-top: 6px;
  width: 100%;
}

.InquiryButton-checkIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: @c24-color-checked;
}
