.RiskChanceLevel {
  &.has-no-header {
    margin-top: 20px;
  }
}

.RiskChanceLevel-levelContainer {
  display: flex;
  border: @c24-border-nobel-grey;
  border-radius: 7px;
  height: 13px;
}

.RiskChanceLevel-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 4.5px 0 4.5px;
  border-color: @c24-color-malaga-grey transparent transparent transparent;
  top: -4px;

  left: 0;
  right: 0;
  margin: auto;

  position: absolute;
}

.RiskChanceLevelItem {
  width: 100%;
  border-right: @c24-border-nobel-grey;
  position: relative;

  &:last-child {
    border-right: none;
  }

  &.is-one {
    background-color: @c24-color-white;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }

  &.is-two {
    background-color: #c5dff3;
  }

  &.is-three {
    background-color: #8cbfe7;
  }

  &.is-four {
    background-color: #53a0dc;
  }

  &.is-five {
    background-color: #1980d0;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
}

.RiskChanceLevel-informationText {
  margin-top: 2px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.RiskChanceLevel-levelContainerTooltip {
  overflow: visible !important;
  position: relative;
}
