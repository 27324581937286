@import '../../../styles/variables/colors';

.TuevLogo {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
  width: 40%;
}

.TuevLogo-svg {
  max-height: 74px;
  cursor: pointer;
  pointer-events: auto;
}

.TuevLogo-tooltipContent {
  color: @c24-color-popup-content;
}

.TuevLogo-tooltipHeadline {
  font-size: 14px;
  font-weight: bold;
}

.TuevLogo-tooltipSubHeadline {
  font-weight: bold;
  color: @c24-color-headline;
}

.TuevLogo-tooltipAddressHeadline {
  margin: 8px 0;
}

.TuevLogo-header {
  font-weight: 700;
  color: @c24-color-dark-grey;
  margin-bottom: 15px;
}

.TuevLogo-tooltip {
  width: 100%;
  justify-content: flex-end;

  .Tooltip {
    max-width: 475px;
  }
}
