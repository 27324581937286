.OnboardingContributionSelectionRuerupCampaignHint-tooltip {
  top: 11px;
  .Tooltip {
    width: 227px;
    font-size: 11px;
    color: @c24-color-dark-grey;
  }

  &.Tooltip-Root {
    position: static;
    align-self: auto;
    overflow: visible;
  }
}

.OnboardingContributionSelectionRuerupCampaignHint-infoIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-gold;
  margin-left: 3px;
  margin-top: 3px;
  cursor: pointer;
}

.OnboardingContributionSelectionRuerupCampaignHint-container {
  background-color: rgba(@c24-color-gold, 0.15);
  border: 1px solid @c24-color-gold;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;

  .is-big {
    font-size: 18px;
  }
}

.OnboardingContributionSelectionRuerupCampaignHint-arrowBackground {
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
  position: relative;
  top: 7px;
  left: 24px;
  background-color: @c24-color-white;
}

.OnboardingContributionSelectionRuerupCampaignHint-arrow {
  width: 100%;
  height: 100%;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: @c24-color-gold;
  background-color: rgba(@c24-color-gold, 0.15);
  background-size: cover;
  position: absolute;
}
