.OnboardingSection-childrenChips {
  margin-top: 5px;
  .InputChip {
    width: 44px;
    padding: 4px 17px;

    &:first-of-type {
      width: 74px;
    }
  }
}
