.Grade {
  position: relative;
  height: 65px;
  float: right;
  cursor: default;

  &.has-overlay {
    cursor: help;
  }
}

.Grade-icon {
  height: 65px;
  width: 55px;
  background-repeat: no-repeat;
  background-size: 55px 65px;
  background-image: url(/grey-grade.svg);
  display: block;

  &.is-golden {
    background-image: url(/gold-grade.svg);
  }
}

.Grade-calculatedValue {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 21px;
  font-size: 20px;
  font-weight: 700;
}

.Grade-subText {
  position: absolute;
  top: 13px;
  width: 100%;
  text-align: center;
  font-size: 9px;
}

.Grade-gradeText {
  position: absolute;
  font-size: 9px;
  top: 51px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  color: @c24-color-grade-text;

  &.is-bold {
    font-weight: 700;
  }
}

//NOTE: Remove when golden banner campaign ends
body:has(.YearEndBanner) .Grade-icon {
  background-image: url(/grey-grade.svg);
}
