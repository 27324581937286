.Popup {
  position: fixed;
  z-index: 100000;
  background: rgba(51, 51, 51, 0.65);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  height: 100vh;
  padding-bottom: calc(env(safe-area-inset-bottom) + var(--safe-area-tap-bar-not-app));
}

.Popup-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.Popup-closeIcon {
  fill: @c24-color-white;
  width: 20px;
  height: 20px;
}

.Popup-content {
  background: @c24-color-white;
  width: 100%;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0;
  max-width: 420px;
  max-height: 85%;
  overflow: scroll;
}
