@import '../../styles/variables/colors';
@import '../../styles/variables/borders';

.ComparisonSelectedTariffsHeader {
  display: flex;
  border-top: @c24-border-default;
  border-bottom: @c24-border-default;
}

.ComparisonSelectedTariffsHeader-tariffBlock {
  border-left: @c24-border-default;
  background-color: @c24-color-white;
  flex: 1;
  position: relative;
  padding: 8px;
  min-width: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &:first-of-type {
    border-left: none;
  }

  &.is-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
  }
}

.ComparisonSelectedTariffsHeader-possiblePension {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 4px;
}

.ComparisonSelectedTariffsHeader-guaranteedPension {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 700;
  color: #333;
}

.ComparisonSelectedTariffsHeader-possiblePensionContainer {
  color: @c24-color-price;
}

.ComparisonSelectedTariffsHeader-pensionDescription {
  display: block;
  font-size: 12px;
}

.ComparisonSelectedTariffsHeader-insuranceDetailsContainer {
  margin-top: 2px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ComparisonSelectedTariffsHeader-insuranceLogo {
  display: block;
  max-height: 100%;
  max-width: 100%;

  @media @c24-s-wide {
    max-height: 55px;
  }
}

.ComparisonSelectedTariffsHeader-close {
  position: absolute;
  right: 8px;
  top: 8px;
  pointer-events: auto;
  width: 16px;
  height: 16px;
  fill: @c24-color-close;
  cursor: pointer;
}

.ComparisonSelectedTariffsHeader-tariffName {
  text-align: center;
  color: @c24-color-black;
  margin-top: 8px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
  flex: 1;
  display: flex;
  align-items: center;
}

.ComparisonSelectedTariffsHeader-pensionAmountContainer {
  text-align: center;
  margin-bottom: 8px;
}

.ComparisonSelectedTariffsHeader-addTariff {
  width: 40px;
  height: 40px;
  fill: @c24-color-icon-default;

  &:hover {
    fill: @c24-color-button-hover;
    cursor: pointer;
  }
}

.ComparisonSelectedTariffsHeader-add {
  padding: 0;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.ComparisonSelectedTariffsHeader-topRow {
  margin-top: -5px;
  margin-left: -3px;
  width: 100%;
  align-self: flex-start;
  min-height: 30px;
}

.ComparisonSelectedTariffsHeader-labelSlot {
  align-self: flex-start;
  min-height: 26px;
  margin-bottom: 5px;
  margin-right: 17px;
}

.ComparisonSelectedTariffsHeader-resultZeroHeader {
  padding-left: 7px;
  padding-right: 7px;

  .ResultZeroHeader-text {
    font-size: 10px;

    @media @small-phone {
      font-size: 8px;
    }

    @media @medium-phone {
      font-size: 9px;
    }

    @media @c24-m-wide {
      font-size: 12px;
    }
  }
}

.ComparisonSelectedTariffsHeader-tariffPromotionHeader {
  .TariffPromotionHeader-content {
    padding-left: 7px;
    padding-right: 6px;
  }

  .TariffPromotionHeader-text {
    font-size: 9px;

    @media @small-phone {
      font-size: 8px;
    }

    @media @medium-phone {
      font-size: 8px;
    }

    @media @c24-m-wide {
      font-size: 12px;
    }
  }
}
