.TariffDetailsPage-consultantBox {
  background: @c24-color-white;
}

.TariffDetailsPage-uspOverview {
  padding: 15px;
  background: @c24-color-white;
  margin-bottom: 15px;
}

.TariffDetailsPage-uspOverviewHeader {
  font-size: 20px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 12px;
}

.TariffDetailsPage-showAllButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  border-top: 1px solid @c24-color-gainsboro-grey;
  margin-top: 24px;
}

.TariffDetailsPage-showAllButtonText {
  font-size: 16px;
}

.TariffDetailsPage-showAllButtonIcon {
  height: 16px;
  width: 9px;
}

.TariffDetailsPage-trustFooterWithDisclaimer {
  background: @c24-color-white;
}
