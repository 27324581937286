.TariffDetailsLabels-labelTooltip {
  max-width: 290px;

  &:after {
    left: 30% !important;
  }

  &:before {
    left: 30% !important;
  }
}

.TariffDetailsLabels-labelWrapper {
  display: flex;
  margin-bottom: 7px;
  position: relative;

  &.is-first {
    margin-top: 5px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.TariffDetailsLabels-label {
  border-radius: 2px;
  line-height: 18px;
  padding: 2px 6px;
  font-size: 14px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.TariffDetailsLabels-helpText {
  font-size: 14px;
  padding: 10px 25px 13px;
}

.TariffDetailsLabels-helpText {
  &:not(:last-of-type) {
    border-bottom: @c24-border-divider-grey;
    margin-bottom: 15px;
  }
  font-size: 14px;
  padding: 10px 25px 13px;
}

.TariffDetailsLabels-tooltip {
  position: absolute;
  top: 0;
  right: 0;
  cursor: help;
}

.TariffDetailsLabels-tooltipIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-question-mark;
  transition-duration: 200ms;
  vertical-align: top;
}
