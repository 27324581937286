.InsurerCarousel-wrapper {
  margin-top: 15px;
  max-width: 1005px;
  height: 70px;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  gap: 50px;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.InsurerCarousel-wrapper::-webkit-scrollbar {
  display: none;
}

.InsurerCarousel-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 200px;
  object-fit: contain;
}
