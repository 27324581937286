.ThankyouPage {
  font-size: 16px;

  .TrustFooter-logoContainer {
    background-color: @c24-color-white-background;
  }
}

.ThankyouPage-container {
  display: flex;
  flex-direction: column;
}

.ThankyouPage-helpText {
  padding-left: 25px;
  width: 230px;
}

.ThankyouPage-overlay {
  margin-top: 25%;
  margin-bottom: 25%;
}
