.PointsAmountLogo-icon {
  width: 55px;
  height: 29px;
}

.PointsAmountLogo {
  height: 29px;
  margin-bottom: 15px;
  position: relative;
}

.PointsAmountLogo-number {
  position: absolute;
  font-size: 10px;
  top: 4px;
  left: 8px;
  font-weight: bold;
  font-style: italic;
  color: @c24-color-black;
  font-family: Verdana;
}
