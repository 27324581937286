.OnboardingInvestmentSelection-radioHeading {
  display: flex;
  align-items: center;

  > svg {
    height: 20px;
    padding-left: 15px;
  }
}

.OnboardingInvestmentSelection-radioSubtitle {
  color: @c24-color-black;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0px;
  margin-top: 8px;
}

.OnboardingInvestmentSelection-radioSvg {
  fill: @c24-color-malaga-grey;
}
