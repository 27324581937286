.ResultTotalTariffsAndRateRange {
  display: flex;
  flex-direction: column;
  margin: 5px 10px 0;
}

.ResultTotalTariffsAndRateRange-totalTariffsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ResultTotalTariffsAndRateRange-straightLine {
  flex-grow: 1;
  height: 1px;
  min-width: 30%;
  background: @c24-color-gainsboro-grey;
}

.ResultTotalTariffsAndRateRange-totalTariffsTitle {
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
  color: @c24-color-black;
  font-size: 16px;
}

.ResultTotalTariffsAndRateRange-priceRangeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 12px;
}
.ResultTotalTariffsAndRateRange-possiblePensionRangeValue {
  font-weight: bold;
  color: @c24-color-dark-green;
  margin: 0;
  font-size: 14px;
}

.ResultTotalTariffsAndRateRange-granitePensionRangeValue {
  font-weight: bold;
  color: @c24-color-black;
  margin: 0;
  font-size: 14px;
}
