@import '../../../styles/variables/colors';
@import '../../../styles/variables/borders';

.YesNoQuestion {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.is-large {
    justify-content: flex-start;
  }

  &.is-error {
    svg {
      path {
        fill: @c24-color-red;
      }
    }
  }
}

.YesNoQuestion-option {
  font-size: @c24-form-input-font-size;
  margin-left: 5px;
  padding-left: 40px;
  position: relative;

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 18px;
    height: 18px;
    border-radius: 10px;
    border: @c24-border-input;
    top: 3px;
    left: 11px;
    transition: 0.3s border-color;
    border-color: @c24-color-nobel-grey;
    background-color: @c24-color-white;
  }

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 8px;
    height: 8px;
    border-radius: 4px;
    top: 8px;
    left: 16px;
    background-color: @c24-color-dark-blue;
    opacity: 0;
  }

  &.is-selected:after {
    opacity: 1;
  }

  &.is-selected:before {
    border-color: @c24-color-dark-blue;
  }

  &.is-error:before {
    border-color: @c24-color-is-error;
  }

  &.is-error:after {
    border-color: @c24-color-is-error;
  }
}

.YesNoQuestion-question {
  display: flex;
  color: @c24-form-input-label-color;
  font-size: @c24-form-input-label-font-size;
  margin-bottom: @c24-form-input-label-margin-bottom;
  padding-right: 2px;
}

.YesNoQuestion-helpText {
  position: absolute;
  right: 0px;
  top: 0px;
  height: @c24-form-input-height;
}

.YesNoQuestion-selection {
  width: 100%;
  display: flex;
  position: relative;
  height: @c24-form-input-height;
  border: @c24-border-input;
  border-radius: @c24-form-input-border-radius;
  background: @c24-color-white;

  &.is-error {
    border: @c24-border-input-error;
  }
}

.YesNoQuestion-button {
  cursor: pointer;
  user-select: none;
  display: flex;
  padding: 5px;
  border: none;
  align-items: center;
  color: @c24-color-input;

  &:nth-child(1) {
    border-top-left-radius: @c24-form-input-border-radius;
    min-width: 40%;
  }

  &:nth-child(2) {
    border-top-right-radius: @c24-form-input-border-radius;
  }
}

.YesNoQuestion-option {
  font-size: @c24-form-input-font-size;
  margin-left: 5px;
}

.YesNoQuestion-icon {
  border: 0;
  margin-right: 0;
  margin-left: 6px;
}
