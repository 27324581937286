.ResultSearchDataBox {
  background: @c24-color-yellow;
  padding: 4px;
  cursor: pointer;
}

.ResultSearchDataBox-content {
  display: flex;
  background: @c24-color-white;
  border-radius: 3px;
  padding: 5px 10px;
  align-items: center;
}

.ResultSearchDataBox-text {
  flex-grow: 1;
}
