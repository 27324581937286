@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/borders';

.VerticalRadioButtonSelect {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: @c24-color-white;
  border: @c24-border-input;
  border-radius: @c24-form-input-border-radius;

  &.is-error {
    border-color: @c24-color-is-error;
  }
}

.VerticalRadioButtonSelect-question {
  display: flex;
  color: @c24-form-input-label-color;
  font-size: @c24-form-input-label-font-size;
  margin-bottom: @c24-form-input-label-margin-bottom;
  padding-right: 2px;
}

.VerticalRadioButtonSelect-helpText {
  position: absolute;
  right: 0px;
  top: 0px;
}

.VerticalRadioButtonSelect-selection {
  display: flex;
  position: relative;
  height: @c24-form-input-height;
  border-bottom: @c24-border-input;
}

.VerticalRadioButtonSelect-button {
  cursor: pointer;
  user-select: none;
  display: flex;
  padding: 14px 5px;
  border: none;
  align-items: center;
  color: @c24-color-input;
  background-color: @c24-color-white-background;
  flex-grow: 1;

  &:first-child {
    border-top-left-radius: @c24-form-input-border-radius;
  }

  &:last-child {
    border-top-right-radius: @c24-form-input-border-radius;
  }

  &.is-error {
    border-bottom: @c24-border-error;
  }
}

.VerticalRadioButtonSelect-option {
  font-size: @c24-form-input-font-size;
  margin-left: 5px;
}

.VerticalRadioButtonSelect-icon {
  border: 0;
  margin-right: 0;
  margin-left: 6px;
}

.VerticalRadioButtonSelect-options {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.VerticalRadioButtonSelect.is-vertical {
  .VerticalRadioButtonSelect-selection {
    height: auto;
    padding: 0 6px;
  }

  .VerticalRadioButtonSelect-options {
    flex-direction: column;
  }

  .VerticalRadioButtonSelect-button {
    border-bottom: @c24-border-light-grey;

    &:last-child {
      border-bottom: none;
    }
  }
}

.VerticalRadioButtonSelect {
  .is-disabled {
    pointer-events: none;
    color: @c24-color-nobel-grey;
  }
}

.VerticalRadioButtonSelect-tooltipContainer {
  margin-top: 14px;
  margin-left: 17px;
  background-color: @c24-color-dark-green-alpha-15;
  width: max-content;
  border-radius: 15px;
  padding: 3px 2px;
  font-size: 16px;
}

.VerticalRadioButtonSelect-tooltip {
  color: @c24-color-dark-green;
  padding: 0px 10px;
}

.VerticalRadioButtonSelect-infoIcon {
  width: 16px;
  height: 16px;
  fill: @c24-color-dark-green;
  margin-top: 3px;
  margin-left: 4px;
}
