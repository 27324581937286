@import '../../styles/variables/colors';
@import '../../styles/variables/borders';
@import '../../styles/variables/component-sizes';

@form-width: 780px;

.PersonalDataForm-headline {
  color: @c24-color-black;
  font-size: 20px;
  margin: 7px 0 11px 13px;
}

.PersonalDataForm-headlineTrustElements {
  margin: 0 13px 17px;
  padding: 20px 0px 8px;
  border-top: @c24-border-divider-grey;
  border-bottom: @c24-border-divider-grey;
}

.PersonalDataForm-headlineTrustElementCheckmark {
  fill: @c24-color-checked;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  margin-right: 10px;
}

.PersonalDataForm-headlineTrustElementRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.PersonalDataForm-formContainer {
  margin-bottom: 16px;

  &.is-tabletApp {
    width: 100%;
  }
}

.PersonalDataForm-ctaSection {
  display: flex;
  margin: 13px;
}

.PersonalDataForm-inputContainer {
  align-self: center;
  flex: 1;
}

.PersonalDataForm-backLink {
  font-family: Arial;
  color: @c24-color-dark-blue;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  &:before {
    content: '\AB';
    display: inline-block;
    margin-right: 5px;
  }
}

.PersonalDataForm-trustElementsContainer {
  display: flex;
  margin-bottom: 5px;
  margin-left: 25px;
  font-size: 13px;
}

.PersonalDataForm-checkSvg {
  margin-top: 2px;
  margin-right: 12px;
  width: 16px;
  height: 12px;
  fill: @c24-color-checked;
}

.PersonalDataForm-inquiryButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 13px 24px 13px;
}

.PersonalDataForm-inquiryButton {
  margin-bottom: 4px;
}

.PersonalDataForm-inquiryButtonHint {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: center;
  color: @c24-color-dark-green;
}

.PersonalDataForm-checkIcon {
  width: 12px;
  height: 10px;
  margin-right: 6px;
  fill: @c24-color-dark-green;
}

.PersonalDataForm-agreementSection {
  margin: 0 13px;
  font-size: 14px;
  line-height: 18px;
}

.PersonalDataForm-noTariffDetailsButtonArea {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  position: sticky;
  bottom: 0;
  z-index: 100;
  background: #fff;
  padding: 10px 10px;
  margin: 0 !important;
  box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.1);
  padding-bottom: ~'calc(env(safe-area-inset-bottom) + var(--safe-area-tap-bar-not-app))';

  &:not(.is-sticky) {
    padding-bottom: 0;
  }
}

.PersonalDataForm-ButtonHint {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 16px;
}

.PersonalDataForm-checkIconBig {
  width: 18px;
  height: 14px;
  margin-right: 6px;
  fill: @c24-color-dark-green;
}
