.AppointmentBookingTool-container {
  background-color: @c24-color-white;
}

.AppointmentBookingTool-wrapper {
  background-color: @c24-color-background-grey;
  padding: 0px 13px 13px;
  padding-top: 10px;
}

.AppointmentBookingTool-heading {
  font-size: 18px;
  margin: 0px;
  font-weight: normal;
}

.AppointmentBookingTool-headingWrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.AppointmentBookingTool-calendarSvg {
  width: 23px;
  height: 26px;
  margin-right: 10px;
  fill: @c24-color-dark-blue;
}

.AppointmentBookingTool-noSelectionError {
  font-size: 16px;
  padding-top: 10px;
  margin: 0px;
  color: @c24-color-red;
}

.AppointmentBookingTool-separationLine {
  background-color: @c24-color-malaga-grey;
  height: 1px;
  margin-top: 10px;
}

.AppointmentBookingTool-emailContactHeader {
  margin: 0px;
  font-size: 16px;
  margin-bottom: 6px;
  margin-top: 18px;
}

.AppointmentBookingTool-emailContactText {
  margin: 0px;
  margin-bottom: 9px;
}

.AppointmentBookingTool-emailWrapper {
  display: flex;
  align-items: center;
}

.AppointmentBookingTool-emailSvg {
  fill: @c24-color-icon-default;
  height: 20px;
  width: 26px;
  margin-right: 10px;
}
