@import '../../../styles/variables/colors';
@import '../../../styles/variables/borders';

.RadioButtonSelect {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: @c24-color-white;

  &.is-error {
    svg {
      path {
        fill: @c24-color-red;
      }
    }
  }
}

.RadioButtonSelect-question {
  display: flex;
  color: @c24-form-input-label-color;
  font-size: @c24-form-input-label-font-size;
  margin-bottom: @c24-form-input-label-margin-bottom;
  padding-right: 2px;
}

.RadioButtonSelect-selection {
  display: flex;
  position: relative;
  height: @c24-form-input-height;
  border: @c24-border-input;
  border-radius: @c24-form-input-border-radius;

  &.is-error {
    border: @c24-border-input-error;
  }
}

.RadioButtonSelect-button {
  cursor: pointer;
  user-select: none;
  display: flex;
  padding: 14px 5px;
  border: none;
  align-items: center;
  color: @c24-color-input;
  flex-grow: 1;
}

.RadioButtonSelect-option {
  font-size: @c24-form-input-font-size;
  margin-left: 5px;
  padding-left: 28px;
  position: relative;

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 18px;
    height: 18px;
    border-radius: 10px;
    border: @c24-border-input;
    left: 0px;
    top: 3px;
    transition: 0.3s border-color;
    background-color: @c24-color-white;
  }

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 8px;
    height: 8px;
    border-radius: 4px;
    top: 8px;
    left: 5px;
    background-color: @c24-color-dark-blue;
    opacity: 0;
  }

  &.is-selected:after {
    opacity: 1;
  }

  &.is-selected:before {
    border-color: @c24-color-dark-blue;
  }

  &.is-error:before {
    border-color: @c24-color-is-error;
  }

  &.is-error:after {
    border-color: @c24-color-is-error;
  }
}

.RadioButtonSelect-icon {
  border: 0;
  margin-right: 0;
  margin-left: 6px;
}

.RadioButtonSelect-options {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.RadioButtonSelect.is-vertical {
  .RadioButtonSelect-selection {
    height: auto;
    padding: 0 6px;
  }
  .RadioButtonSelect-options {
    flex-direction: column;
  }
  .RadioButtonSelect-button {
    border-bottom: @c24-border-light-grey;

    &:last-child {
      border-bottom: none;
    }
  }
}
