.AppointmentBookingExpiredSession-container {
  padding: 0 13px 20px;
  background: white;
}

.AppointmentBookingExpiredSession-header {
  display: flex;
  padding-top: 20px;
  border-top: 1px solid @c24-color-malaga-grey;
}

.AppointmentBookingExpiredSession-headerText {
  margin-left: 10px;
}
