.Dropdown {
  width: 100%;
  position: relative;
  border: @c24-border-input;
  border-radius: @c24-form-input-border-radius;
  font-size: 18px;
  padding: 0 7px;
  color: @c24-color-input;
  background: @c24-color-input-background;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  height: 50px;
  min-height: 50px;

  &:hover {
    border-bottom: @c24-border-input-hover;
  }

  &:focus,
  &:active {
    background-position: right -12px;
  }

  &.is-active {
    .Dropdown-dropdownIndicator {
      fill: @c24-color-dark-blue;
    }
  }

  &.is-error {
    border: @c24-border-input-error;
    background-position: right -44px;

    .Dropdown-dropdownIndicator {
      fill: @c24-color-is-error;
    }
  }

  &.is-disabled {
    background: @c24-color-disabled-background;
    color: @c24-color-input-placeholder;
  }
}

.Dropdown-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: @c24-color-is-error;
  font-size: 12px;
}

.Dropdown-dropdownIndicator {
  width: 18px;
  height: 8px;
  fill: @c24-color-nobel-grey;
  &.is-active {
    fill: @c24-color-dark-blue;
  }
}
