@import '../../../styles/variables/colors';
@import '../../../styles/variables/box-shadows';
@import '../../../styles/variables/borders';
@import '../../../styles/variables/backgrounds';

.Button {
  display: block;
  width: 100%;
  background-color: @c24-color-button-background;
  padding: 10px;
  color: @c24-color-button-text;
  border-radius: 3px;
  font-size: 20px;
  font-family: Arial, Helvetica, Sans-Serif;
  border: @c24-border-button;
  pointer-events: auto;
  text-align: center;
  box-shadow: @c24-box-button-shadow;

  &:focus,
  &:active,
  &:active:focus,
  &:hover {
    outline: none;
    box-shadow: @c24-box-button-shadow;
    text-decoration: none;
  }

  &:hover {
    background-color: @c24-color-button-hover;
  }

  &.is-disabled {
    color: @c24-color-button-disabled;
    background-color: @c24-color-button-disabled-background;
    border: 1px solid @c24-color-button-disabled-background;

    &.is-recommendation {
      height: 40px;
    }
  }

  .Button-navigationSpinner {
    display: inline-block;
    position: relative;
    top: 0;
    height: 10px;
    margin-left: 22px;
    margin-right: -22px;
    &:after {
      height: 24px;
      width: 24px;
      margin-left: -12px;
      margin-top: -12px;
      border-width: 4px;
      content: '';
      border-color: #fff rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4);
      top: 50%;
      box-sizing: border-box;
      display: block;
      position: absolute;
      left: 50%;
      border-style: solid;
      border-radius: 50%;
      animation: spinAround 0.7s infinite linear;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 24px;
        width: 24px;
        /* IE spinner jumps, thus reset border and use svg as background image */
        border: 0;
        border-radius: 0;
        background-image: url(/button-spinner.svg);
        background-size: 24px;
      }
    }
  }

  // Button--secondary is never set by a prop on the Button element, rather it is set in 1 place with an explicit class name
  // on the custom button component; InquiryButton.
  // For now lets leave this here and consider refactoring if a mobile redesign happens.
  &.Button--secondary {
    border: @c24-border-light-grey;
    background-color: @c24-color-white;
    color: @c24-color-malaga-grey;
    box-shadow: @c24-box-secondary-button-shadow;

    &:active,
    &:active:focus,
    &:hover,
    &:focus {
      background-color: @c24-color-background-light-grey;
      text-decoration: none;
      border: @c24-border-light-grey;
    }
  }

  &.Button--secondary .Button-navigationSpinner:after {
    border-color: @c24-color-dark-blue rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      background-image: url(/button-spinner-inverted.svg);
    }
  }

  &.is-narrow .Button-navigationSpinner:after {
    height: 16px;
    width: 16px;
    margin-left: -18px;
    margin-top: -8px;
    border-width: 3px;
  }
}
