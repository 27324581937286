.PensionGapBanner {
  display: flex;
  align-items: center;
}

.PensionGapBanner-banner {
  background-color: @c24-color-light-orange;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.PensionGapBanner-contentBox {
  -webkit-text-size-adjust: none; // disable font resize in safari
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.PensionGapBanner-content {
  width: 100%;
}

.PensionGapBanner-endDateImage {
  position: relative;
  margin: 3px 5px 3px 9px;
}

.PensionGapBanner-countdownImg {
  display: block;
  width: 50px;
  height: 50px;
}

.PensionGapBanner-calendarImg {
  display: block;
  width: 50px;
  height: 50px;
  margin: 3px 5px 3px 9px;
}

.PensionGapBanner-logos {
  margin-left: 30px;

  @media @c24-tablet-l-wide {
    display: none;
  }
}

.PensionGapBanner-daysLeft {
  font-size: 12px;
  color: @c24-color-catalina-blue;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 50px;
  font-weight: bold;
  line-height: 1;
}

.PensionGapBanner-days {
  font-size: 11px;
}

.PensionGapBanner-daysLeft-days {
  font-size: 20px;
  color: @c24-color-light-orange;
}

.PensionGapBanner-daysLeft-hours {
  font-size: 16px;
  font-weight: 700;
  color: @c24-color-light-orange;
  margin-top: 2px;
}

.PensionGapBanner-daysLeft-minutes {
  font-size: 11px;
  font-weight: 700;
  color: @c24-color-catalina-blue;
}
