@import '../../../styles/variables/colors';
@import '../../../styles/variables/backgrounds';

.Checkbox {
  font-size: 13px;
  &.is-multiline {
    width: 100%;
    .Checkbox-icon {
      margin-top: 3px;
    }
  }

  &.has-help-text {
    width: 80%;
  }
}

.Checkbox-button {
  display: flex;
  width: 100%;
  border: 0;
  outline: @c24-color-button-outline;
  padding-left: 0;
  text-align: left;
  cursor: pointer;
  margin-top: -2px;
}

.Checkbox-icon {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  margin-right: 7px;
  border-radius: 3px;
  background-color: @c24-color-light-button-background;
  pointer-events: none;

  &.Checkbox-icon--off {
    fill: @c24-color-checkbox-unchecked;
  }

  .has-error & {
    fill: @c24-color-is-error;
  }

  &.Checkbox-icon--on {
    fill: @c24-color-checkbox-checked;
  }

  &.Checkbox-icon--isHovering {
    fill: @c24-color-checkbox-checked;
  }
}
