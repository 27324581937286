.ConsultantCarousel-wrapper {
  margin-top: 15px;
  max-width: 1005px;
}

.slick-prev,
.slick-next {
  z-index: 1 !important;
  fill: @c24-color-light-grey;
  height: 100% !important;
  width: 10px !important;

  &:before {
    content: '' !important;
    position: absolute !important;
    top: 0px !important;
    left: -15px !important;
    right: -15px !important;
    bottom: 0px !important;
    margin: auto;
  }
}

.slick-next {
  right: 15px !important;
}

.slick-prev {
  left: 15px !important;
}

.nextArrow,
.prevArrow {
  height: 36px;
  width: 10px;
}
