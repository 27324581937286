.OnboardingProductSelectionSection-divider {
  height: 1px;
  background-color: @c24-color-divider-grey;
  margin: 15px;
}

.OnboardingProductSelectionSection-productSelectionTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: @c24-border-gainsboro-grey;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}

.OnboardingProductSelectionSection-productSelectionTableHeader {
  display: flex;
  top: 0;
  transform: none !important;
}

.OnboardingProductSelectionSection-productSelectionOption {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  flex-direction: column;
  padding: 40px 10px 16px;
  position: relative;
  border-right: @c24-border-gainsboro-grey;

  .Popup-container {
    text-align: left;
  }

  &:last-of-type {
    border-right: none;
  }

  &.is-disabled {
    background-color: @c24-color-background-light-grey;
  }
}

.OnboardingProductSelectionSection-productSelectionOptionTitle {
  font-weight: bold;
  width: auto;

  &.is-recommended {
    color: @c24-color-dark-green;
  }
}

.OnboardingProductSelectionSection-productSelectionOptionBadge {
  font-size: 14px;
  padding: 5px 0px;
  color: @c24-color-dark-green;
  background-color: @c24-color-dark-green-alpha-15;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  &.is-first {
    border-top-left-radius: 5px;
  }

  &.is-last {
    border-top-right-radius: 5px;
  }

  .Tooltip-Root {
    width: 18px;
    height: 20px;
  }
}

.OnboardingProductSelectionSection-productSelectionOptionBadgeIcon {
  margin-left: 5px;
  width: 13px;
  height: 13px;
  fill: @c24-color-dark-green;
}

.OnboardingProductSelectionSection-productSelectionOptionHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.OnboardingProductSelectionSection-productSelectionOptionTooltip {
  margin-top: 10px;
}

.OnboardingProductSelectionSection-questionMarkIcon {
  width: 18px;
  height: 18px;
  fill: @c24-color-nobel-grey;
}

.OnboardingProductSelectionSection-showDetailsButton {
  font-size: 16px;
  color: @c24-color-dark-blue;
  text-decoration: none;
  text-align: center;
  padding: 12px;
  border-top: @c24-border-gainsboro-grey;

  &.is-disabled {
    color: @c24-color-grey;
  }
}

.OnboardingProductSelectionSection-showDetailsButtonIcon {
  width: 15px;
  height: 8px;
  fill: @c24-color-dark-blue;
  margin-right: 10px;
  rotate: 180deg;
  transition: all 0.3s ease-in-out;
  transition-delay: 150ms;

  &.is-up {
    rotate: 360deg;
  }

  &.is-disabled {
    fill: @c24-color-grey;
  }
}

.OnboardingProductSelectionSection-productSelectionOptionRecommendationTooltip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.OnboardingSection-row.OnboardingProductSelectionSection-row {
  margin-bottom: 8px;
}

.OnboardingProductSelectionSection-featureIcon {
  margin-right: 6px;
  position: relative;
  top: 2px;

  > svg {
    width: 20px;
    height: 20px;
  }
}

.OnboardingProductSelectionSection-productSelectionTableStickyHeaderContainer {
  visibility: hidden;
  height: 0;

  .OnboardingProductSelectionSection-productSelectionTableStickyHeader {
    opacity: 0;

    > .OnboardingProductSelectionSection-productSelectionOption
      > .OnboardingProductSelectionSection-productSelectionOptionTooltip {
      display: none !important;
    }

    &.sticky {
      visibility: visible;
      height: fit-content;
      opacity: 1;
      transition:
        visibility 0s,
        opacity 0.3s ease;
      display: flex;
      top: 0;
      transform: none !important;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
      z-index: 100;
      background: @c24-color-white;
    }
  }
}

.OnboardingProductSelectionSection-infoBox {
  display: flex;
  align-items: center;
  padding: 10px;
  background: @c24-color-vivid-orange-alpha-15;
  font-size: 16px;
  margin-bottom: 20px;
  border-left: 3px solid @c24-color-vivid-orange;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.OnboardingProductSelectionSection-text {
  flex: 1;
}

.OnboardingProductSelectionSection-infoIcon {
  fill: @c24-color-vivid-orange;
  width: 23px;
  height: 23px;
  margin-right: 10px;
}
