.PensionGapBannerContent {
  display: flex;
}

.PensionGapBannerContent-textColumn {
  flex-grow: 1;
  padding-left: 13px;
  @media @small-phone, @medium-phone {
    padding-left: 3px;
    font-size: 15px;
  }

  > span {
    white-space: nowrap;
    display: block;
    line-height: 1;
    color: @c24-color-catalina-blue;
  }
}

.PensionGapBannerContent-tooltipColumn {
  display: flex;
  align-items: center;
  padding-right: 13px;
}

.PensionGapBannerContent-tooltip {
  font-size: 12px;
  .Tooltip {
    width: 433px;
  }
}

.PensionGapBannerContent-tooltipContentSection {
  margin-bottom: 15px;
  font-weight: initial;

  &:last-child {
    margin-bottom: 0;
  }
}

.PensionGapBannerContent-infoIcon {
  width: 22px;
  height: 13px;
  fill: @c24-color-catalina-blue;
}

.PensionGapBannerContent-hint {
  display: inline-block;
}
