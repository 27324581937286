.EFeedbackFilterableRatings-container {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  top: 10px;
  right: 15px;
}

.EFeedbackFilterableRatings-starsContainer {
  display: flex;
  padding-bottom: 12px;
}

.EFeedbackFilterableRatings-numberOfRatings {
  display: flex;
  justify-content: center;
  color: @c24-color-black;
  margin-left: 5px;
  font-size: 14px;
  line-height: 15px;
}
