.SummaryInput-label {
  font-size: @c24-form-input-label-font-size;
  color: @c24-form-input-label-color;
  margin-bottom: @c24-form-input-label-margin-bottom;
  position: relative;
}

.SummaryInput-labelHelpText {
  right: 10px;
  top: 3px;
  position: absolute;
  font-size: 16px;
  color: @c24-color-black;
}
