.TariffFilter {
  position: relative;
  display: block;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.TariffFilter-content {
  display: flex;
}

.TariffFilter-buttons {
  border: @c24-border-divider-grey;
  font-size: 14px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  background-color: @c24-color-white;
  transition-duration: 200ms;
  display: flex;
  justify-content: center;
  align-items: center;

  &.is-selected {
    background-color: @c24-color-light-blue;
    z-index: 1;
  }
}

.TariffFilter-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.TariffFilter-tooltipIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-question-mark;
  transition-duration: 200ms;
  vertical-align: top;
}
