.SideHelpText-questionMark {
  width: 20px;
  height: 20px;
  fill: @c24-color-question-mark;
}

.SideHelpText-headline {
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 16px;
  color: @c24-color-black;
}

.SideHelpText {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SideHelpText-popup {
  height: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.SideHelpText-content {
  font-size: 16px;
  color: @c24-color-black;
}
