.TariffDetailsHeader-wrapper {
  background: @c24-color-white;
  width: 100%;
  margin-bottom: 15px;
  white-space: nowrap;
}

.TariffDetailsHeader-info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  padding: 8px 15px 15px;
}

.TariffDetailsHeader-stars {
  display: flex;
  align-items: center;
  gap: 8px;
}

.TariffDetailsHeader-numberOfRatings {
  font-size: 14px;
}
