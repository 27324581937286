.SummaryDetail-line {
  margin-top: 13px;

  span {
    white-space: nowrap;
  }

  &.has-devider {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: @c24-border-nobel-grey;
  }
}
