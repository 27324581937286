.PlaceholderTariff {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PlaceholderTariff-header {
  display: flex;
  padding: 15px 20px 0px;
  align-items: center;
}

.PlaceholderTariff-headerImage {
  height: 100px;
}

.PlaceholderTariff-headerText {
  font-size: 16px;
  font-weight: bold;
}

.PlaceholderTariff-descriptionText {
  padding: 0px 20px;
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
}

.PlaceholderTariff-cta {
  margin-top: 20px;
  margin-bottom: 5px;
}

.PlaceholderTariff-hint {
  color: @c24-color-button-hint;
  font-size: 12px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.PlaceholderTariff-checkIcon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
