.ResultSuggestionHeadingBox {
  display: flex;
  background-color: @c24-color-light-blue;
  padding: 0px 15px 8px 15px;
  font-size: 14px;
}

.ResultSuggestionHeadingBox-thumbsUpSvg {
  width: 16px;
  height: 17px;
}

.ResultSuggestionHeadingBox-title {
  margin: 0;
  margin-left: 8px;
  font-size: 14px;
  color: @c24-color-black;
}

.ResultSuggestionHeadingBox-infoTextSvg {
  width: 19px;
  height: 19px;
  margin-left: auto;
  fill: @c24-color-nobel-grey;
  margin-top: 2px;
}
