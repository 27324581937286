@import '../../../styles/variables/colors';

.SecurityHint {
  display: flex;
  color: @c24-color-dark-green;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  margin-top: 6px;
  justify-content: center;
}

.SecurityHint-lockIcon {
  width: 12px;
  height: 10px;
  margin-right: 6px;
  fill: @c24-color-dark-green;
}
