.TariffFeatureDetails-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.TariffFeatureDetails-descriptionContainer {
  display: flex;
  width: 100%;
}

.TariffFeatureDetails-featureValue {
  min-width: 44px;
  font-size: 14px;
  font-weight: bold;
  > svg {
    width: 20px;
    height: 20px;
    margin-top: 2px;
  }
}

.TariffFeatureDetails-titleAndComment {
  padding-right: 20px;
}

.TariffFeatureDetails-title {
  font-size: 14px;
  margin: 0;
  font-weight: bold;
}

.TariffFeatureDetails-comment {
  font-size: 14px;
  margin: 0;
}

.TariffFeatureDetails-helpText {
  font-size: 14px;
}

.TariffFeatureDetails-tooltipIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-question-mark;
}
