@import '../../styles/variables/borders';

.OnboardingSofortSection-label {
  font-size: @c24-form-input-label-font-size;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: @c24-form-input-label-color;
  margin-bottom: @c24-form-input-label-margin-bottom;
  .Popup-content {
    color: @c24-color-black;
    font-size: 16px;
  }
}

.OnboardingSofortSection-input {
  display: block;
}
