@import '../../../styles/variables/colors';
@import '../../../styles/variables/box-shadows';
@import '../../../styles/variables/borders';
@import '../../../styles/variables/backgrounds';

.SocialMediaButtons {
  display: block;
  width: 160px;
  border-radius: 3px;
  font-size: 14px;
  border: 0 none;
  cursor: pointer;
  pointer-events: auto;
  text-align: center;
  background-color: @c24-color-inverted-button-background;
  color: @c24-color-light-button-text;
  border: @c24-border-inverted-button;
  font-weight: bold;
  height: 34px;
}

.SocialMediaButtons-icon {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
  margin-right: 15px;
}
