@import '../styles/variables/colors';

.Page {
  color: @c24-color-page-text;
  font-family: Arial, Helvetica, Sans-Serif;
  font-size: 12px;
  line-height: 1.428571429; // 20/14;
  min-width: 1px;

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  max-width: @c24-max-page-size;
  width: 100%;
  margin: 0 auto;
}
