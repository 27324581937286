.TariffDetailsEFeedback {
  padding: 15px;
  background-color: @c24-color-white;
  margin-bottom: 15px;
}

.TariffDetailsEFeedback-header {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 20px;
}

.TariffDetailsEFeedback-showAllButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  border-top: 1px solid @c24-color-gainsboro-grey;
  margin-top: 10px;
}

.TariffDetailsEFeedback-showAllButtonText {
  font-size: 16px;
}

.TariffDetailsEFeedback-showAllButtonIcon {
  height: 16px;
  width: 9px;
}
