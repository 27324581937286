.AppointmentBookingCta-container {
  padding: 10px 0px 7px;

  &.is-sticky {
    position: sticky;
    bottom: 0;
    transition: all 0.5s ease 0s;
    width: 100%;
    z-index: 8;
    background: #fff;
    padding: 10px 10px;
    padding-bottom: ~'calc(env(safe-area-inset-bottom) + var(--safe-area-tap-bar-not-app) + 10px)';
    margin: 0 !important;
    .AppointmentBookingCta-textWrapper {
      margin-top: 5px;
    }
  }
}

.AppointmentBookingCta-checkSvg {
  height: 13px;
  width: 16px;
  fill: @c24-color-dark-green;
  margin-right: 8px;
}

.AppointmentBookingCta-textWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.AppointmentBookingCta-text {
  font-size: 16px;
  color: @c24-color-dark-green;
  line-height: 18px;
}
