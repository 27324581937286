.TariffDetailsUspList-tooltipAnchor {
  position: absolute;
  left: 30px;
  top: 5px;
}

.TariffDetailsUspList-container {
  flex: 1;
  min-width: 40%;
  font-size: 16px;
  margin-bottom: 4px;
}

.TariffDetailsUspList-feature {
  margin-bottom: 7px;
  display: flex;
  position: relative;
}

.TariffDetailsUspList-featureContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.TariffDetailsUspList-feature {
  &:last-of-type {
    margin-bottom: 10px;
  }
}

.TariffDetailsUspList-featureIcon {
  margin-right: 6px;
  position: relative;
  display: flex;
  flex-direction: column;

  > svg {
    width: 20px;
    height: 20px;
  }
}

.TariffDetailsUspList-helpText {
  &:not(:last-of-type) {
    border-bottom: @c24-border-divider-grey;
    margin-bottom: 15px;
  }
  font-size: 14px;
  padding: 10px 25px 13px;
}

.TariffDetailsUspList-tooltip {
  position: absolute;
  top: 0;
  right: 0;
  cursor: help;
}

.TariffDetailsUspList-tooltipIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-question-mark;
  transition-duration: 200ms;
  vertical-align: top;
}
