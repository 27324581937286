.TariffSelectionCheckbox-checkbox {
  position: absolute;
  left: -30px;
  top: 0;
  &.is-disabled {
    .Checkbox-icon {
      background-color: @c24-color-background-dark-grey;
    }
  }
}

.TariffSelectionCheckbox-checkbox .Checkbox-button {
  margin: 0 !important;
}

.TariffSelectionCheckbox-checkbox .Checkbox-icon {
  margin: 0 !important;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
}
