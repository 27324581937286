.ConsultantCarouselItem-phoneNumber {
  font-size: 18px;
  color: @c24-color-dark-blue;
  display: flex;
  justify-content: left;
}

.ConsultantCarouselItem-container {
  border: 1px solid @c24-color-divider-grey;
  border-radius: 3px;
  background-color: @c24-color-white;
  padding: 10px 15px;
}

.ConsultantCarouselItem-consultantDetails {
  margin-top: 10px;
}

.ConsultantCarouselItem-phoneSvg {
  fill: @c24-color-icon-default;
  width: 14px;
  height: 20px;
  margin-right: 5px;
}

.ConsultantCarouselItem-additionalText {
  display: flex;
  align-items: flex-end;
  color: @c24-color-dark-blue;
  fill: @c24-color-dark-blue;
  font-size: 12px;
  width: 75px;
  justify-content: flex-end;
}

.ConsultantCarouselItem-expandCollapseArrow {
  width: 13px;
  height: 7px;
  margin-left: 5px;
  bottom: 6px;
  position: relative;
}

.ConsultantCarouselItem-consultantDetails {
  display: flex;
  justify-content: space-between;
}

.ConsultantCarouselItem-infoTitle {
  font-size: 14px;
  color: @c24-color-dark-grey;
}

.ConsultantCarouselItem-name {
  font-size: 16px;
  color: @c24-color-dark-grey;
  margin-top: 6px;
  font-weight: bold;
}

.ConsultantCarouselItem-photoAndInfo {
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.ConsultantCarouselItem-photoContainer {
  margin-right: 10px;
  min-width: 70px;
}

.ConsultantCarouselItem-languages {
  width: 250px;
  margin-right: -20px;
}

.ConsultantCarouselItem-photo {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  border: 1px solid @c24-color-light-grey;
}

.ConsultantCarouselItem-quote {
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  background-color: @c24-color-background-grey;
  color: @c24-color-dark-grey;
  position: relative;
  height: 100%;
  margin: 0 20px;
}

.ConsultantCarouselItem-arrow {
  display: block;
  position: absolute;
  top: 0%;
  left: 13px;
  width: 11px;

  &:after {
    content: ' ';
    border-image: none;
    border-bottom: 20px solid @c24-color-background-grey;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    width: 1px;
    position: absolute;
    margin-top: -12px;
    top: 50%;
  }
}
