.PersonalLogin-iframeContainer {
  min-height: 300px;
}

.PersonalLogin-iframe {
  display: block;
}

.TariffHeaderBox {
  &.PersonalLogin-tariffHeaderBox {
    border-bottom: @c24-border-light-grey;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  }
}
