.VerticalRadio-header {
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
}

.VerticalRadio-button {
  cursor: pointer;
  user-select: none;
  display: flex;
  padding: 14px 5px;
  border: none;
  align-items: flex-start;
  color: @c24-color-input;
  width: 100%;
}

.VerticalRadio-separator {
  width: 100%;
  height: 1px;
  padding-left: 10px;
  padding-right: 10px;

  div {
    border-bottom: @c24-border-input;
  }
}

.VerticalRadio-label {
  font-size: @c24-form-input-label-font-size;
  color: @c24-form-input-label-color;
  margin-bottom: 12px;
  align-items: center;
  display: flex;
  position: relative;
}

.VerticalRadio-helpText {
  height: 26px;
}

.VerticalRadio-options {
  border: @c24-border-input;
  border-radius: @c24-form-input-border-radius;

  &.is-error {
    border: @c24-border-error;
  }
}

.VerticalRadio-option {
  width: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 18px;
    height: 18px;
    border-radius: 10px;
    border: @c24-border-input;
    top: 3px;
    left: 11px;
    transition: 0.3s border-color;
    border-color: @c24-color-nobel-grey;
    background-color: @c24-color-white;
  }

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 8px;
    height: 8px;
    border-radius: 4px;
    top: 8px;
    left: 16px;
    background-color: @c24-color-dark-blue;
    opacity: 0;
  }

  &.is-selected:after {
    opacity: 1;
  }

  &.is-selected:before {
    border-color: @c24-color-dark-blue;
  }

  &.is-error:before {
    border-color: @c24-color-is-error;
  }

  &.is-error:after {
    border-color: @c24-color-is-error;
  }
}
.VerticalRadio-optionText {
  margin-top: 2px;
  font-size: @c24-form-input-font-size;
  margin-left: 40px;
}

.VerticalRadio-optionDescription {
  font-size: 16px;
  margin-left: 40px;
  margin-top: 10px;
}

.VerticalRadio-icon {
  border: 0;
  margin-right: 0;
  margin-left: 6px;
}
