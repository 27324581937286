@import '../../../styles/variables/colors.less';

.TariffListOverlay {
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 998;

  &.is-visiblyHidden {
    visibility: hidden;
  }
}

.TariffListOverlay-spinner {
  text-align: center;
}

.TariffListOverlay-wrapper {
  position: fixed;
  z-index: 999;
  background-color: @c24-color-white;
  padding: 15px;
  left: 11px;
  top: 50%;
  width: calc(100% - 22px);
  transform: translate(0, -50%);
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

.TariffListOverlay-container {
  position: relative;
}

.TariffListOverlay-spinnerText {
  z-index: 1000;
  cursor: default;
  color: @c24-color-dark-blue;
  font-size: 18px;
  margin-top: 10px;
}

.TariffListOverlay-blocker {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: black;
}

.TariffListOverlay-text {
  font-size: 20px;
  color: @c24-color-dark-grey;
  text-align: left;
}

.TariffListOverlay-footer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 15px;
}

.TariffListOverlay-checkmarks {
  margin-bottom: 20px;
}

.TariffListOverlay-checkmarkItem {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  span {
    max-width: 300px;
  }
}

.TariffListOverlay-checkmarkSvg {
  fill: @c24-color-dark-blue;
  width: 26px;
  min-width: 26px;
  height: 26px;
  margin-right: 10px;
}

.TariffListOverlay-logosContainer {
  display: flex;
  align-items: flex-end;
}

.TariffListOverlay-logo {
  height: 105px;

  &.TariffListOverlay-tuevLogo {
    width: 105px;
    height: auto;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.TariffListOverlay-recommendation {
  padding: 10px;
  font-size: 18px;
  color: @c24-color-dark-grey;
}

.TariffListOverlay-recommendationFooter {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-top: 20px;
}

.TariffListOverlay-recommendationCheckmarkItem {
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.TariffListOverlay-recommendationCheckmarkSvg {
  fill: @c24-color-dark-green;
  height: 24px;
  width: 29px;
  min-width: 29px;
  margin-right: 10px;
  margin-top: 5px;
}

.TariffListOverlay-recommendationCta {
  margin-top: 30px;
  max-width: 300px;
  align-self: center;

  .Button-navigationSpinner {
    top: -1px;
    height: 10px;
    margin-left: 20px;
    margin-right: -1px;
  }
}

.TariffListOverlay-close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: -2px;
  top: -2px;
  cursor: pointer;
  fill: @c24-color-grey;
}

.TariffListOverlay-missingTariffContainer {
  padding: 10px;
  font-size: 18px;
  color: @c24-color-black;
}

.TariffListOverlay-infoIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  fill: @c24-color-vivid-orange;
}

.TariffListOverlay-infoBox {
  display: flex;
}

.TariffListOverlay-missingTariffCta {
  height: 49px;
  align-self: center;
  margin-top: 23px;
}
