.ResultPage-container {
  display: flex;
  margin-bottom: 6px;
}

.ResultPage-tariffs {
  position: relative;
  width: 100%;
  padding-bottom: 30px;
}

.BackToTopButton {
  display: inline;
  position: fixed;
  bottom: ~'calc(env(safe-area-inset-bottom) + var(--safe-area-tap-bar-not-app) + 15px)';
  right: 15px;
  opacity: 0.6;
}

.ResultPage-campaignRow {
  position: relative;
  margin: 5px 10px 10px;
}

.ResultPage-campaignBanners {
  border-radius: 3px;
  overflow: hidden;
}
