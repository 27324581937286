.Summary {
  pointer-events: none;
  margin-top: 15px;
  width: 100%;
}

.Summary-input {
  position: relative;
  display: block;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}

.Summary-details {
  color: @c24-color-malaga-grey;
  font-size: 16px;
  margin-top: 14px;

  &.is-sofort {
    margin-top: 6px;
  }
}

.Summary-tooltipIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-question-mark;
  transition-duration: 200ms;
  vertical-align: top;
}

.Summary-tooltip {
  position: absolute;
  top: 0;
  right: 0;
  cursor: help;
}

.Summary-stickyGoToResultsContainer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  padding-bottom: ~'calc(env(safe-area-inset-bottom) + var(--safe-area-tap-bar-not-app) + 20px)';
  background-color: @c24-color-white;
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.2);
  z-index: 36;
}
