.TariffFeaturesDocumentCategory-category {
  background-color: @c24-color-white;
  padding: 15px;
}

.TariffFeaturesDocumentCategory-categoryTitle {
  margin: 0;
  padding-bottom: 14px;
  font-size: 16px;
}

.TariffFeaturesDocumentCategory-documentsListContainer {
  padding-top: 18px;
  border-top: 1px solid @c24-color-light-nobel-grey;
}

.TariffFeaturesDocumentCategory-document {
  display: flex;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  margin-bottom: 10px;
}

.TariffFeaturesDocumentCategory-documentIcon {
  height: 20px;
  width: 20px;
  min-width: 44px;
}

.TariffFeaturesDocumentCategory-documentLink {
  font-size: 14px;
  text-decoration: underline;
}
