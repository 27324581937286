@import '../../styles/variables/backgrounds';
@import '../../styles/variables/colors';

.ComparisonSelectedTariffsFeatures {
  width: 100%;
}

.ComparisonSelectedTariffsFeatures-filterContainer {
  background-color: @c24-color-white;
  border-bottom: @c24-border-comparison-features;
  padding: 10px;
}

.ComparisonSelectedTariffsFeatures-filterOptions {
  border-radius: 20px;
  background-color: @c24-color-background-grey;
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.ComparisonSelectedTariffsFeatures-option {
  color: @c24-color-comparison-feature-filter-text;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  user-select: none;

  &.is-selected {
    color: @c24-color-comparison-feature-selected-filter-text;
    background-color: @c24-color-comparison-feature-selected-filter-background;
  }
}

.ComparisonSelectedTariffsFeatures-categoryTitle {
  background: @c24-color-white;
  padding: 10px;
  color: @c24-color-dark-blue;
  position: relative;
  font-size: 20px;
  border-bottom: @c24-border-comparison-features;
}

.ComparisonSelectedTariffsFeatures-selectedFilterText {
  display: none;
}

.ComparisonSelectedTariffsFeatures-expandArrow {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 15px;
  fill: @c24-color-dark-blue;
  transform: rotate(180deg);

  * {
    fill: @c24-color-dark-blue;
  }

  &.is-collapsed {
    transform: initial;
  }
}
