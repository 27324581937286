.TextAreaInput {
  position: relative;
  width: 100%;
}

.TextAreaInput-field {
  width: 100%;
  min-height: 47px;
  color: @c24-color-input;
  border: @c24-border-input;
  border-radius: 5px;
  font-weight: normal;
  padding: 8px 11px;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
  will-change: border-color;
  resize: none;
  font-family: inherit;
  line-height: 20px;
  font-size: 16px;
  vertical-align: top;

  &:hover:enabled {
    border: @c24-border-input-hover;
    padding: 8px 11px;
  }

  &:focus,
  &:active,
  &:focus:hover {
    border: @c24-border-input-focus;
    padding: 7px 10px;
  }

  &.is-error,
  &.is-error:focus,
  &.is-error:active,
  &.is-error:hover,
  &.is-error:focus:hover {
    border: @c24-border-error;
  }

  &.is-disabled {
    background-color: @c24-color-disabled-background;
    -webkit-text-fill-color: @c24-color-input; /* required on iOS */
    opacity: 1; /* required on iOS */
  }

  &:read-only {
    background-color: @c24-color-disabled-background;
  }
}

.TextAreaInput-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: @c24-color-is-error;
  font-size: 16px;
}
