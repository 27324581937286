.OnboardingSofortPage {
  display: flex;
}

.OnboardingSofortPage-formContainer {
  width: 100%;
}

.OnboardingSofortPage-additionalInformation {
  display: flex;
  justify-content: space-between;
}
