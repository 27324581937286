.ComparisonInquiryButton-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ComparisonInquiryButton-container {
  pointer-events: none;
  &.is-secondary {
    margin-top: 20px;
  }
}

.ComparisonInquiryButton-tooltip {
  .Tooltip {
    width: auto;
    white-space: nowrap;
  }
}
