.ResultTariffListItemLabels-labelTooltip {
  max-width: 290px;

  &:after {
    left: 30% !important;
  }

  &:before {
    left: 30% !important;
  }
}

.ResultTariffListItemLabels-labelWrapper {
  display: flex;
  margin-bottom: 7px;

  &.is-first {
    margin-top: 5px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.is-detail {
    padding-top: 15px;
    border-top: @c24-border-divider-grey;
  }
}

.ResultTariffListItemLabels-label {
  border-radius: 2px;
  line-height: 18px;
  padding: 2px 6px;
  font-size: 14px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.ResultTariffListItemLabels-helpText {
  font-size: 14px;
  padding: 10px 25px 13px;
}
