.ConsultantBox-container {
  margin-top: 15px;
  padding: 15px;
  background-color: @c24-color-background-light-grey;
  border: 1px solid @c24-color-divider-grey;
  border-radius: 3px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.26);
}

.ConsultantBox-row {
  display: flex;
  align-items: stretch;
}

.ConsultantBox-photo {
  display: flex;
  justify-content: center;
  position: relative;
  margin-right: 15px;
  margin-left: 15px;
}

.ConsultantBox-quote {
  width: 100%;
  font-size: 13px;
  border-radius: 3px;
  line-height: 18px;
  border: 1px solid @c24-color-divider-grey;
  background-color: @c24-color-light-blue;
  color: @c24-color-dark-grey;
  position: relative;
  margin-top: 15px;
}

.ConsultantBox-quoteText {
  background-color: @c24-color-light-blue;
  min-height: 55px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 12px;
  padding-left: 15px;
}

.ConsultantBox-quoteArrow {
  width: 15px;
  height: 15px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: #dcdcdc;
  background-color: #ecf7fd;
  background-size: cover;
  transform: rotate(43deg);
  position: absolute;
  left: 30px;
  bottom: -23px;
  z-index: 1;
}

.ConsultantBox-photoImage {
  display: inline;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-position: top center;
  background-size: cover;
}

.ConsultantBox-name {
  font-size: 16px;
  line-height: 18px;
}

.ConsultantBox-expertText {
  color: @c24-color-malaga-grey;
  font-size: 13px;
  margin-top: 8px;
  text-align: center;
}

.ConsultantBox-phoneSvg {
  fill: @c24-color-icon-default;
  height: 21px;
  width: 14px;
  margin-right: 8px;
}

.ConsultantBox-phoneNumber {
  font-size: 13px;
  align-self: flex-end;
}

.ConsultantBox-phoneNumberContainer {
  margin-top: 8px;
}

.ConsultantBox-contactTextContainer {
  display: flex;
  align-items: center;
}

.ConsultantBox-workingTimes {
  margin-top: 5px;
  color: @c24-color-grey;
  line-height: 14px;
}

.ConsultantBox-header {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
}
