.TariffPromotionHeader {
  display: flex;
}

.TariffPromotionHeader-content {
  padding-left: 10px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  border-radius: 14px;
  width: fit-content;
  min-height: 26px;
}

.TariffPromotionHeader-text {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.TariffPromotionHeader-tooltipIcon {
  height: 14px;
  width: 14px;
  pointer-events: auto;
  margin-left: 5px;
  cursor: help;
}

.TariffPromotionHeader-tooltipContentList {
  list-style-type: disc;
}
