@import '../../styles/variables/colors';
@import '../../styles/variables/borders';

.ResultTariffListItem {
  display: flex;
  flex-direction: column;
  background-color: @c24-color-white;
  cursor: pointer;
  border: @c24-border-divider-grey;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  user-select: none;
}

.ResultTariffListItem-container {
  position: relative;
  transition-duration: 200ms;
  transition-property: margin-left;

  &.is-comparison-mode {
    margin-left: 30px;
  }
}

.ResultTariffListItem-index {
  color: @c24-color-tariff-index;
  font-size: 12px;

  @media @narrow-desktop {
    margin-bottom: 4px;
  }

  @media @c24-tablet-wide {
    margin-bottom: 4px;
  }
}

.ResultTariffListItem-itemContainer {
  display: flex;
  flex-direction: column;
  margin: 0px;
}

.ResultTariffListItem-row {
  margin: 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.ResultTariffListItem-headerRow {
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: @c24-border-divider-grey;
  }

  &.ResultTariffListItem-footerRow {
    margin: 0;
    position: relative;
  }
}

.ResultTariffListItem-left {
  flex: 1;
  flex-direction: column;
  display: flex;
}

.ResultTariffListItem-right {
  width: 21%;
}

.ResultTariffListItem-top {
  display: flex;
  flex-grow: 1;
}

.ResultTariffListItem-bottom {
  display: flex;
  align-items: flex-end;
  height: 30px;

  & > div:nth-child(1) {
    width: 24%;
    margin-right: 70px;

    @media @narrow-desktop {
      margin-right: 35px;
    }

    @media @c24-tablet-wide {
      margin-right: 35px;
    }
  }

  & > div:nth-child(2) {
    width: 110px;
    margin-right: 4%;
    margin-bottom: -11px;

    &.is-selected {
      height: 39px;
    }

    @media @c24-s-wide {
      width: 120px;
      margin-right: 3%;
    }

    @media @c24-wide {
      width: 120px;
      margin-right: 40px;
    }

    @media @c24-tablet-wide {
      margin-right: 6%;
    }
  }

  & > div:nth-child(3) {
    &.is-selected {
      height: 39px;
      margin-bottom: -8px;
    }

    @media @narrow-desktop {
      margin-left: 4%;
    }
  }
}

.ResultTariffListItem-priceContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 160px;
}

.ResultTariffListItem-price {
  line-height: 18px;
  text-align: right;
  margin-bottom: 5px;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    right: 12px;
  }

  .Tooltip {
    text-align: start;
  }
}

.ResultTariffListItem-priceInterval {
  font-size: 13px;
  font-weight: normal;
  float: right;
  color: @c24-color-dark-grey;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    right: 12px;
  }
}

.ResultTariffListItem-insuranceContainer {
  flex-grow: 1;
}

.ResultTariffListItem-priceToolTip {
  position: relative;
}

.ResultTariffListItem-insuranceLogo {
  display: block;
  max-height: 60px;
  max-width: 100%;

  @media @desktop {
    max-height: 55px;
  }

  @media @c24-wide {
    max-height: 60px;
  }
}

.ResultTariffListItem-tariffName {
  margin-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}

.ResultTariffListItem-actionButton {
  padding: 5px;
  height: 40px;

  @media @c24-wide {
    font-size: 15px;
  }
}

.ResultTariffListItem-cta {
  width: 100%;
  display: flex;
  margin-top: 5px;
}

.ResultTariffListItem-ctaContainer {
  display: flex;
  width: 20%;
  justify-content: flex-end;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ResultTariffListItem-ctaContainer {
    width: 22%;
  }
}

@-moz-document url-prefix() {
  .ResultTariffListItem-tabArrow {
    left: 95px;

    @media @c24-wide {
      left: 105px;
    }
  }
}

.ResultTariffListItem-debugInformationContainer {
  padding: 5px;
}

.ResultTariffListItem-possiblePrice {
  font-size: 24px;
  font-weight: bold;
  color: @c24-color-price;
  margin-bottom: 7px;
}

.ResultTariffListItem-guaranteedPrice {
  font-size: 20px;
  font-weight: bold;
  color: @c24-color-black;
  margin-top: 7px;
  margin-bottom: 2px;
}

.ResultTariffListItem-priceDescription {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.is-possible {
    color: @c24-color-price;
  }
}

.ResultTariffListItem-priceText {
  font-size: 12px;
  line-height: 16px;
}

.ResultTariffListItem-infoSvgIcon {
  height: 12px;
  width: 12px;
  pointer-events: auto;
  fill: @c24-color-nobel-grey;
}

.ResultTariffListItem-tariffPromotionHeader {
  margin-top: 7px;
}

.ResultTariffListItem-topRow {
  display: flex;
  padding: 10px;
  padding-bottom: 0;
  justify-content: space-between;
  align-items: flex-start;
}

.ResultTariffListItem-indexContainer {
  display: flex;
  padding: 8px 15px;
  justify-content: space-between;

  &.has-label {
    padding: 3px 15px 0px;
  }
}

.ResultTariffListItem-promotion {
  margin-bottom: 5px;
}

.ResultTariffListItem-resultZero {
  margin-bottom: 5px;
}
