.YearEndBannerContent {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.YearEndBannerContent-description {
  margin: 0px;
  padding-top: 11px;
  padding-bottom: 13px;
  padding-left: 11px;
  position: relative;
  display: inline-flex;
}

.YearEndBannerContent-tooltip {
  font-size: 12px;
  .Tooltip {
    width: 433px;
  }
}

.YearEndBannerContent-tooltipContentSection {
  margin-bottom: 15px;
  font-weight: initial;
  color: @c24-color-black;
  &:last-child {
    margin-bottom: 0;
  }
}

.YearEndBannerContent-infoIcon {
  width: 22px;
  height: 13px;
  fill: @c24-color-white;
}

.YearEndBannerContent-hint {
  display: inline-block;
}

.YearEndBanner-countDown {
  display: flex;
  align-items: center;
  padding-right: 7px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.YearEndBanner-timeBoxContent {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.YearEndBanner-timeBoxTimeValue {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: @c24-color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  & p {
    color: @c24-color-black;
    margin: 0;
    font-size: 20px;
  }
}

.YearEndBanner-timeBoxContainer {
  display: flex;
  align-items: center;
  &:not(:last-child)::after {
    content: ':';
    font-size: 20px;
    margin: 0 5px;
    margin-bottom: 20px;
  }
}

.YearEndBanner-timeBoxTimeDescription {
  font-size: 12px;
  margin: 0;
  max-width: 43px;
  text-align: center;
}

.YearEndBanner-timeBoxSeparator {
  font-size: 20px;
  margin: 0 5px;
}
