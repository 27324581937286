@import '../../styles/variables/borders';

.OnboardingSection {
  display: block;
  padding: 15px;

  &.OnboardingSection--noPaddingBottom {
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 22px;
  }
}

.OnboardingSection-header {
  font-size: 20px;
  color: @c24-color-black;
  margin-bottom: 20px;
  line-height: 20px;
}

.OnboardingSection-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  &.is-row {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0px;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
}

.OnboardingSection-label {
  font-size: @c24-form-input-label-font-size;
  color: @c24-form-input-label-color;
  margin-bottom: @c24-form-input-label-margin-bottom;
  align-items: center;
  display: flex;
  position: relative;

  .OnboardingSection-labelHelpText {
    right: 0px;
    top: 0px;
    position: absolute;
    height: 22px;
  }

  &.has-labelHelpText {
    padding-right: 32px;
  }
}

.OnboardingSection-input {
  display: block;
}
