.InsuranceList {
  padding-top: 5px;
  background-color: @c24-color-white;
  color: @c24-color-black;

  ul {
    margin: 0px;
    li {
      list-style-type: disc;
    }
  }
}

.InsuranceList-overviewText {
  margin-left: 15px;
  margin-right: 15px;
}

.InsuranceList-contents {
  margin-left: 15px;
  margin-right: 15px;
}

.InsuranceList-content {
  padding-left: 15px;
  padding-right: 15px;
  background-color: @c24-color-white;
  font-size: 15px;
}

.InsuranceList-disclaimerText {
  font-size: 12px;
}

.InsuranceList-headline {
  margin-top: 10px;
  color: @c24-color-black;
  font-weight: 300;
  font-size: 20px;
}

.InsuranceList-subHeadline {
  padding-top: 20px;
  font-size: 20px;
  font-weight: 300;
  color: @c24-color-malaga-grey;
  margin: 0;
}

.InsuranceList-backButton {
  font-size: 13px;
  font-weight: bold;
}

.InsuranceList-link {
  display: block;
  padding: 0;
  color: @c24-color-link;
  font-size: 14px;
  margin-bottom: 5px;
}

.InsuranceList-dateOfValidity {
  color: @c24-color-light-grey;
  padding-top: 10px;
  padding-bottom: 10px;
}

.InsuranceList-column {
  padding: 10px;
}

.InsuranceList-investmentTypeContainer {
  ul {
    margin: 0px;
    padding-left: 15px;
  }
}

.InsuranceList-investmentTypeHeader {
  margin-bottom: 5px;
}

.InsuranceList-list {
  margin: 0;
  padding-left: 16px;
}

.InsuranceList-label {
  font-size: 15px;
  font-weight: bold;
}

.InsuranceList-header {
  font-weight: bold;
  color: @c24-color-dark-grey;
  text-align: center;

  .InsuranceList-column {
    margin-top: 25px;
    border-bottom: none;
    padding-top: 10px;
  }

  .InsuranceList-label {
    border-bottom: none;
  }
}

.InsuranceList-main-header {
  border-bottom: 1px solid @c24-color-grey;
  border-top: none;
}

.InsuranceList-main-header * {
  padding: 0 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.InsuranceList-downloadContainer {
  margin-left: 15px;
  padding-bottom: 15px;
  font-size: 14px;
}

.InsuranceList-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: relative;
  top: 4px;
}

.InsuranceList-description {
  font-size: 12px;
}
