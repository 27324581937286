.InputChips {
  color: @c24-color-black;
  padding-top: 5px;
  margin-bottom: -10px;
}

.InputChip {
  height: 30px;
  border-radius: 15px;
  display: inline-block;
  padding: 4px 13px;
  font-size: 16px;
  background-color: @c24-color-white;
  cursor: pointer;
  user-select: none;
  border: @c24-border-divider-grey;
  margin-right: 10px;
  margin-bottom: 10px;

  @supports (-webkit-touch-callout: none) {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &.is-selected {
    background-color: @c24-color-light-blue;
    border: @c24-border-dark-blue;
  }
}
