@import '../../../styles/variables/colors';
@import '../../../styles/variables/backgrounds';

.PersonalDataSsoBox {
  background-color: @c24-background-login-box;
  padding: 20px;
  margin-bottom: 5px;
}

.PersonalDataSsoBox-title {
  display: flex;
  position: relative;
}

.PersonalDataSsoBox-row {
  display: flex;
  position: relative;
  width: 100%;
  font-size: 15px;
  margin-bottom: 20px;
}

.PersonalDataSsoBox-label {
  width: 32%;
  margin-left: 62px;
  margin-top: 10px;
}

.PersonalDataSsoBox-input {
  width: 53%;
}

.PersonalDataSsoBox-container {
  display: flex;
  position: relative;
}

.PersonalDataSsoBox-icon {
  width: 42px;
  height: 34px;
  margin-right: 20px;
  margin-top: 6px;
}

.PersonalDataSsoBox-infoText {
  font-size: 15px;
  max-width: 590px;
}

.PersonalDataSsoBox-infoTextHeader {
  font-size: 18px;
  color: @c24-color-headline;
}

.PersonalDataSsoBox-toggleLoginBoxButton {
  margin-left: auto;
  width: 150px;
}

.PersonalDataSsoBox-closeButton {
  margin-left: auto;
  align-self: flex-start;
  width: 70px;
  margin-right: 5px;
}

.PersonalDataSsoBox-ctaButton {
  margin-left: auto;
  width: 150px;
  height: 34px;
  padding: 0;
}

.PersonalDataSsoBox-loginButton {
  .PersonalDataSsoBox-ctaButton;
  position: absolute;
  margin-top: 6px;
}

.PersonalDataSsoBox-logout {
  margin-left: auto;
  align-self: flex-start;
  width: 70px;
  position: relative;
  top: 5px;
  text-align: right;
}

.PersonalDataSsoBox-expandArrow {
  position: absolute;
  right: 16px;
  top: 13px;
  width: 10px;
  height: 10px;
  fill: @c24-color-white;
}

.PersonalDataSsoBox-collapseArrow {
  position: absolute;
  width: 10px;
  height: 10px;
  transform: rotate(180deg);
  right: 0;
  top: 3px;
  fill: @c24-color-icon-default;
}

.PersonalDataSsoBox-sideHelpText {
  right: 6px;
  font-size: 12px;
}

.PersonalDataSsoBox-formFooter {
  display: flex;
  justify-content: space-between;
}
