.PersonalDataSection-container {
  display: block;
  padding: 0 13px 0;
}

.PersonalDataSection-row {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 6px;

  &:last-of-type {
    margin-bottom: 25px;
  }

  &.has-inline-label {
    margin-top: 10px;
  }
}

.PersonalDataSection-label {
  font-size: @c24-form-input-label-font-size;
  color: @c24-form-input-label-color;
  margin-bottom: @c24-form-input-label-margin-bottom;
  margin-top: 10px;
}

.PersonalDataSection-labelSubtitle {
  font-size: 11px;
  color: @c24-color-grey;
}

.PersonalDataSection-input {
  display: flex;
  > div {
    flex-grow: 1;
    margin-right: 10px;
  }
}

.PersonalDataSectionBirthday-input {
  .PersonalDataSection-input;

  font-size: 13px;
  margin-left: 10px;
  margin-top: 10px;
}

.PersonalDataSection-bestSso {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
}

.PersonalDataSection-bestSsoFullName {
  font-weight: bold;
}

.PersonalDataSection-bestSsoPersonalData {
  flex: 1;
  font-size: 15px;
}

.PersonalDataSection-bestSsoChange {
  font-size: 15px;
}

.PersonalDataSection-bestSsoSideHelpText {
  margin-top: 2px;
}

.PersonalDataSection-agreementLink {
  text-decoration: underline;
}

.PersonalDataSection-updateSsoDataRow {
  margin-top: 25px;
}

.PersonalDataSection-updateSsoData {
  .Checkbox-label {
    font-size: 18px;
  }
}
