.OnboardingProductSelectionCalculationTip-productCalculationHintWrapper {
  display: flex;
  margin-top: 16px;
  margin-bottom: -15px;
  color: @c24-color-dark-blue;
  font-size: 16px;
}

.OnboardingProductSelectionCalculationTip-productCalculationHint {
  padding-left: 5px;
  display: flex;
  text-align: start;
}

.OnboardingProductSelectionCalculationTip-modalBody {
  padding: 15px 0px;
}

.OnboardingProductSelectionCalculationTip-modalHeading {
  font-size: 20px;
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 7px;
  padding-left: 15px;
}

.OnboardingProductSelectionCalculationTip-modalText {
  font-size: 18px;
  margin: 0px;
  color: @c24-color-malaga-grey;
  padding-left: 15px;
}

.OnboardingProductSelectionCalculationTip-modalTable {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
}

.OnboardingProductSelectionCalculationTip-modalCustomerCalculationHeader {
  color: @c24-color-dark-green;
}

.OnboardingProductSelectionCalculationTip-modalCustomerCalculationData {
  display: flex;
  flex-direction: column;
  color: @c24-color-dark-green;

  > span {
    padding: 5px;
  }

  > span:first-of-type {
    border-bottom: 1px solid @c24-color-dark-green;
    padding-bottom: 0px;
  }

  > span:last-of-type {
    padding-top: 1px;
  }
}

.OnboardingProductSelectionCalculationTip-modalCustomerCalculationDataPercentage {
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: @c24-color-dark-green;
}

.OnboardingProductSelectionCalculationTip-disclaimer {
  font-size: 10px;
  line-height: normal;
  margin-top: 25px;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 10px;
  border-top: 1px solid @c24-color-gainsboro-grey;
}
