.InitialInformationModal-container {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(51, 51, 51, 0.4);
  z-index: 200;
  align-items: center;
  overflow-x: auto;
  padding-bottom: env(safe-area-inset-bottom);
}

.InitialInformationModal-body {
  z-index: 101;
  background-color: #fff;
  border-radius: 6px;
  margin: 6px;
  margin-top: 0px;
  max-width: ~'min(520px, 95vw)';
  min-height: 0px; // required to force flex to calculate body height correctly
  overflow: scroll;
}

.InitialInformationModal-header {
  text-align: center;
  font-size: 24px;
  padding-top: 50px;
}

.InitialInformationModal-list {
  display: flex;
  justify-content: center;
  margin: 10px;
  font-size: 16px;
  line-height: 26px;
}

.InitialInformationModal-footer {
  background-color: @c24-color-background-grey;
  text-align: center;

  padding: 24px 35px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;

  &InitialInformationModal-Link {
    margin: 15px 0;
  }
}

.InitialInformationModal-check {
  fill: @c24-color-dark-blue;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.InitialInformationModal-logo {
  height: 80px;
  width: 80px;
  min-height: 80px;
  min-width: 80px;
  padding: 5px;
  position: relative;
  border-radius: 50%;
  background: @c24-color-catalina-blue;
  z-index: 102;
  margin-bottom: -40px;
  margin-top: 6px;

  * {
    fill: white;
  }
}

.InitialInformationModal-footerLink {
  margin-top: 10px;
}

.InitialInformationModal-pdf {
  height: 20px;
  width: 20px;
  margin-right: 7px;
  position: relative;
  top: 3px;
}

.InitialInformationModal-button {
  margin-top: 20px;
  width: 300px;
}
