@import '../../styles/variables/colors';

.ComparisonStickyHeader {
  background: white;
  position: absolute;
  width: 100%;

  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0.3s ease;
}

.ComparisonPage-stickyContainer {
  .sticky {
    background: white;
    z-index: 1500;

    .ComparisonStickyHeader {
      visibility: visible;
      opacity: 1;

      display: flex;
      border-right: @c24-border-default;
      border-left: @c24-border-default;
      box-shadow:
        0 1px 3px rgba(0, 0, 0, 0.12),
        0 1px 2px rgba(0, 0, 0, 0.24);
    }
  }
}

.ComparisonStickyHeader-insuranceLogo {
  max-width: 100%;
  max-height: 100%;
}

.ComparisonStickyHeader-priceContainer {
  text-align: right;
  min-width: 100px;
  line-height: 15px;
}

.ComparisonStickyHeader-insuranceNameAndPrice {
  display: flex;
  margin-top: 10px;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.ComparisonStickyHeader-insuranceName {
  color: @c24-color-black;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  font-weight: bold;
}

.ComparisonStickyHeader-tariffBlockDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
}

.ComparisonStickyHeader-tariffBlock {
  .ComparisonSelectedTariffsHeader-tariffBlock;

  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-of-type {
    border-right: none;
  }

  &.is-empty {
    padding: 11px 10px;
    display: flex;
    align-items: center;
    pointer-events: auto;
    flex-direction: column;
    justify-content: center;
  }

  .ComparisonPage-detailsButton {
    border-bottom: none;
  }
}

.ComparisonStickyHeader-resultLinkContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  background-color: @c24-color-background-dark-grey;
  padding: 10px 10px;
}

.ComparisonStickyHeader-addTariff {
  width: 40px;
  height: 40px;
  fill: @c24-color-icon-default;

  &:hover {
    fill: @c24-color-button-hover;
    cursor: pointer;
  }
}

.ComparisonStickyHeader-add {
  padding: 0;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.ComparisonStickyHeader-resultZeroHeader {
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 5px;

  .ResultZeroHeader-text {
    font-size: 10px;

    @media @small-phone {
      font-size: 8px;
    }

    @media @medium-phone {
      font-size: 9px;
    }

    @media @c24-m-wide {
      font-size: 12px;
    }
  }
}

.ComparisonStickyHeader-topRow {
  margin-top: -5px;
  margin-left: -3px;
  width: 100%;
  align-self: flex-start;
}

.ComparisonStickyHeader-labelSlot {
  align-self: flex-start;
  height: 26px;
  margin-bottom: 5px;
}

.ComparisonStickyHeader-tariffPromotionHeader {
  .TariffPromotionHeader-content {
    padding-left: 7px;
    padding-right: 6px;
  }

  .TariffPromotionHeader-text {
    font-size: 9px;

    @media @small-phone {
      font-size: 8px;
    }

    @media @medium-phone {
      font-size: 8px;
    }

    @media @c24-m-wide {
      font-size: 12px;
    }
  }
}

.ComparisonStickyHeader-close {
  position: absolute;
  right: 16px;
  top: 7px;
  pointer-events: auto;
  width: 14px;
  height: 14px;
  fill: @c24-color-close;
  cursor: pointer;
}
