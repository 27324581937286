.ConsultantContactBox-wrapper {
  padding: 15px;
}

.ConsultantContactBox-container {
  position: relative;
  padding: 15px;
  background-color: @c24-color-white;
  border-radius: 3px;
  border: 1px solid @c24-color-divider-grey;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.ConsultantContactBox-headline {
  color: @c24-color-malaga-grey;
  font-size: 18px;
  margin-bottom: 10px;
}

.ConsultantContactBox-consultant {
  display: flex;
  margin-bottom: 10px;
}

.ConsultantContactBox-consultantName {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.ConsultantContactBox-consultantTitle,
.ConsultantContactBox-workingTimes {
  color: @c24-color-malaga-grey;
  font-size: 12px;
  margin-bottom: 5px;
}

.ConsultantContactBox-photoContainer {
  margin-right: 25px;
  margin-left: 15px;
  min-width: 70px;
  height: 70px;
}

.ConsultantContactBox-photo {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  border: 1px solid @c24-color-light-grey;
}

.ConsultantContactBox-arrow {
  width: 15px;
  height: 15px;
  margin-left: 43px;
  margin-bottom: -8px;
  z-index: 1;

  transform: rotate(45deg);
  border-top: 1px solid @c24-color-gainsboro-grey;
  border-left: 1px solid @c24-color-gainsboro-grey;
  background-color: @c24-color-background-grey;
}

.ConsultantContactBox-quote {
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: @c24-color-background-grey;
  height: 100%;
  border: 1px solid @c24-color-gainsboro-grey;
  margin-bottom: 15px;
}

.ConsultantContactBox-quoteText {
  margin: 0;
  text-align: center;
}

.ConsultantContactBox-phoneButtonContainer {
  padding-bottom: 10px;
}

.ConsultantContactBox-phoneButton.Button--white,
.ConsultantContactBox-emailButton.Button--white {
  border: 1px solid @c24-color-dark-blue;

  &:hover,
  &:active,
  &:focus {
    background-color: @c24-color-background-light-grey;
    border: 1px solid @c24-color-dark-blue;
  }
}

.ConsultantContactBox-phoneSvg {
  fill: @c24-color-dark-blue;
  width: 23px;
  height: 30px;
  margin-right: 3px;
}

.ConsultantContactBox-emailSvg {
  fill: @c24-color-dark-blue;
  width: 30px;
  height: 22px;
  margin-right: 10px;
}

.ConsultantContactBox-phoneNumber,
.ConsultantContactBox-email {
  width: 170px;
  color: @c24-color-dark-blue;
}

.ConsultantContactBox-phoneButton,
.ConsultantContactBox-emailButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ConsultantContactBox-consultantInformation {
  flex-direction: column;
  display: flex;
  justify-content: center;
}
