@import '../../styles/variables/borders';

.ComparisonDocuments-document {
  display: block;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.ComparisonDocuments-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  position: relative;
  top: 4px;
}

.ComparisonDocuments-feature {
  display: flex;
  position: relative;
  border-bottom: @c24-border-comparison-features;

  &:hover {
    box-shadow: 0 0 5px 0 #999999;
    z-index: 900;
  }
}

.ComparisonDocuments-tariffFeature {
  background-color: @c24-color-white;
  flex: 1;
  padding-left: 10px;
  line-height: 40px;
  min-height: 40px;
  font-size: 11px;
  border-left: @c24-border-comparison-features;

  @media @c24-wide {
    font-size: 12px;
  }
}

.ComparisonDocuments-featureName {
  width: 98%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
}
