@import '../../../styles/variables/colors.less';

.InsuranceListLink {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid @c24-color-divider-grey;
  border-bottom: 1px solid @c24-color-divider-grey;
}

.InsuranceListLink-text {
  flex: 1;
  text-align: center;
  color: @c24-color-dark-blue;
}

.InsuranceListLink-icon {
  width: 17px;
  height: 20px;
  margin-top: 2px;
  margin-right: 15px;
}
