.OnboardingSteps {
  display: flex;
  background-color: white;
  padding: 13px 15px 20px;
  justify-content: space-between;
  border-bottom: @c24-border-light-grey;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}

.OnboardingSteps-step {
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative;
  &:last-child {
    flex-grow: initial;
  }

  svg {
    height: 28px;
    width: 28px;
    display: block;
    fill: @c24-color-nobel-grey;
  }

  .OnboardingSteps-stepLine {
    flex-grow: 1;
    height: 2px;
    background: @c24-color-nobel-grey;
  }

  .OnboardingSteps-stepPointer {
    width: 18px;
    height: 18px;
    position: absolute;
    background: @c24-color-white;
    left: 5px;
    top: 40px;
    transform: rotate(45deg);
    border-left: @c24-border-light-grey;
    border-top: @c24-border-light-grey;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    -webkit-clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
    clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
  }
}

.OnboardingSteps-stepPointerShadowCover {
  background: @c24-color-white;
  width: 24px;
  height: 14px;
  position: absolute;
  top: 48px;
  left: 2px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.OnboardingSteps-step--is-active,
.OnboardingSteps-step--is-completed {
  svg {
    fill: @c24-color-dark-blue;
  }
}

.OnboardingSteps-step--is-completed {
  .OnboardingSteps-stepLine {
    background: @c24-color-dark-blue;
  }
}
