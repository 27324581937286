.ResultTariffListItemTariffInfo {
  margin-top: 10px;
}

.ResultTariffListItemTariffInfo-Link {
  color: @c24-color-wireframe-dark-blue;
  font-size: 14px;
  padding: 0px 15px 12px;
  min-width: 170px;
}

.ResultTariffListItemTariffInfo-Close {
  color: @c24-color-wireframe-dark-blue;
  font-size: 14px;
  padding: 0px 15px;
  text-align: right;
}

.ResultTariffListItemTariffInfo-expandCollapseArrow {
  width: 13px;
  height: 7px;
  margin-left: 0px;
  bottom: 1px;
  position: relative;
  margin-left: 5px;
  fill: @c24-color-wireframe-dark-blue;
}

.ResultTariffListItemTariffInfo-Content {
  border-top: @c24-border-divider-grey;
  padding: 15px 15px 10px;
}
