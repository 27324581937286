.OnboardingSummaryPage-container {
  display: flex;
  margin-bottom: 6px;
}

.OnboardingSummaryPage-tariffs {
  position: relative;
  width: 100%;
  padding-bottom: 30px;
}
