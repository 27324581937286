.ResultTariffListItemEFeedback-container {
  flex: 1;
  min-width: 40%;
  font-size: 16px;
  margin-bottom: 4px;
  margin-right: 10px;
}

.ResultTariffListItemEFeedback-wrapper {
  padding-top: 15px;
  margin-bottom: 6px;
  display: flex;
  position: relative;
  border-top: @c24-border-divider-grey;
}

.ResultTariffListItemEFeedback-icon {
  margin-right: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 2px;

  > svg {
    width: 20px;
    height: 20px;
  }

  * {
    fill: @c24-color-black;
  }
}

.ResultTariffListItemEFeedback-headlineText {
  font-size: 16px;
  line-height: 23px;
  margin-top: 1px;
}

.ResultTariffListItemEFeedback-infoText {
  font-size: 14px;
  padding: 10px 25px 13px;
}
