.ResultSummary {
  font-size: 14px;
  display: block;
  margin-top: 5px;
  text-align: center;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.ResultSummary-resultsDetails {
  padding: 0 10px;
}

.ResultSummary-comparisonHint {
  display: block;
  align-self: flex-start;
  font-size: 16px;
}

.ResultSummary-resultsCount {
  font-weight: bold;
}

.ResultSummary-priceRange {
  display: inline;
}

.ResultSummary-possiblePensionRangeValue {
  font-weight: bold;
  color: @c24-color-dark-green;
}

.ResultSummary-guaranteedPensionRangeValue {
  font-weight: bold;
  color: @c24-color-black;
}
