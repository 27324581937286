.ContractCtaArea {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  position: sticky;
  bottom: 0;
  z-index: 100;
  background: #fff;
  padding: 10px 10px;
  padding-bottom: ~'calc(env(safe-area-inset-bottom) + var(--safe-area-tap-bar-not-app))';
  margin: 0 !important;
  box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.1);
  &:not(.is-sticky) {
    padding-bottom: 0;
  }
}
.ContractCtaArea-buttonHint {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 16px;
}
.ContractCtaArea-checkIconBig {
  width: 18px;
  height: 14px;
  margin-right: 6px;
  fill: @c24-color-dark-green;
}
.ContractCtaArea-inquiryButton {
  margin-bottom: 4px;

  &.Button--secondary {
    background: @c24-color-white;
    color: @c24-color-malaga-grey;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1) !important;
    border: 1px solid @c24-color-light-grey;
  }
}
