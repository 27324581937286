.EFeedbackRatings-container {
  display: flex;
  white-space: nowrap;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 15px;

  &.showDetails {
    top: 20px;
    right: 0px;
    @media screen and (max-width: 350px) {
      position: relative;
      top: 0px;
    }
  }
}

.EFeedbackRatings-numberOfRatings {
  display: flex;
  justify-content: center;
  color: @c24-color-black;
  margin-left: 5px;
  font-size: 14px;
  line-height: 15px;
}
