.ResultGuarranteeBox {
  display: flex;
  background: linear-gradient(90deg, @c24-color-catalina-blue 49%, @c24-color-sky-blue 100%);
  padding: 8px 15px;
  margin: 7px 10px 10px;
  align-items: flex-start;
  font-size: 14px;
  border-radius: 3px;
  color: @c24-color-white !important;
  position: relative;
  align-items: center;
}

.ResultGuarranteeBox-icon {
  height: 65px;
  width: 55px;
  margin-right: 20px;
}

.ResultGuarranteeBox-shieldIcon {
  margin-right: 20px;
  height: 65px;
  width: 55px;
}

.ResultGuarranteeBox-text {
  font-family: Arial;
  font-size: 16px;
  color: @c24-color-white;
  line-height: 19px;
  margin-bottom: 5px;
}

.ResultGuarranteeBox-tooltip {
  display: inline-flex;
  margin-top: 2px;
  vertical-align: top;
}

.ResultGuarranteeBox-infoIcon {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  & path {
    fill: @c24-color-white;
  }
}

.PensionGapBannerContent-tooltipColumn {
  display: flex;
  align-items: center;
  padding-right: 13px;
}

.PensionGapBannerContent-tooltip {
  font-size: 12px;
  .Tooltip {
    width: 433px;
  }
}

.PensionGapBannerContent-tooltipContentSection {
  margin-bottom: 15px;
  font-weight: initial;
  color: black;
  overflow-y: scroll;
  &:last-child {
    margin-bottom: 0;
  }
}
