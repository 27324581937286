@import '../../../styles/variables/colors';
@import '../../../styles/variables/backgrounds';

.TariffSelectionBar {
  position: fixed;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 10;
  overflow: hidden;
  bottom: 0;
  transition-duration: 200ms;
  transform: translate3d(0, ~'calc(env(safe-area-inset-bottom) + var(--safe-area-tap-bar-not-app) + 70px)', 0);
  padding: 10px;
  box-shadow: 0 -1px 3px 0 rgb(0 0 0 / 50%);

  &.TariffSelectionBar-isShown {
    transform: translate3d(0, 0, 0);
    padding-bottom: ~'calc(env(safe-area-inset-bottom) + var(--safe-area-tap-bar-not-app) + 20px)';
  }

  button {
    height: 45px;
    padding: 0;
  }
}

.TariffSelectionBar-container {
  display: flex;
  align-items: center;
  max-width: @c24-max-page-size-wide;
  height: 100px;
  margin: 0 auto;

  @media @c24-wide {
    width: @c24-max-page-size-wide;
  }
}

.TariffSelectionBar-selectedTariffs {
  display: flex;
  justify-content: space-between;
  flex: 1;
  min-width: 767px;

  @media @c24-wide {
    max-width: 1020px;
    font-size: 13px;
  }
}

.TariffSelectionBar-tariff {
  display: flex;
  width: 225px;
  height: 80px;
  margin-left: 10px;
  padding: 5px;
  background: @c24-color-tariff-selection-bar-background;
  background-origin: content-box;
  position: relative;
  align-items: flex-end;

  &.is-resultZero {
    background: @c24-result-zero-tariff-background;
  }

  &.TariffSelectionBar-tariff--empty {
    text-align: center;
    display: block;
    align-items: center;
    background-color: black;
    font-size: 25px;
    color: @c24-color-tariff-selection-bar-empty-text;
    justify-content: center;
    padding-top: 13px;
  }
}

.TariffSelectionBar-closeButton {
  cursor: pointer;
  content: '';
  position: absolute;
  right: 5px;
  top: 5px;
  height: 16px;
  width: 16px;
  fill: @c24-color-close;

  &:hover {
    fill: @c24-color-icon-hover;
  }
}

.TariffSelectionBar-tariffName {
  margin-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  max-width: 105px;
}

.TariffSelectionBar-amountContainer {
  font-weight: bold;
  position: absolute;
  bottom: 3px;
  right: 5px;
}

.TariffSelectionBar-price {
  font-size: 16px;
  color: @c24-color-price;
}

.TariffSelectionBar-insuranceLogo {
  position: absolute;
  left: 5px;
  top: 5px;
  height: 45px;
  width: 120px;
  margin: 0 auto;
  max-width: 110px;

  svg {
    max-height: 50px;
  }
}

.TariffSelectionBar-options {
  display: flex;
  flex-wrap: wrap;
  height: 80px;
  margin: 0 10px;
  justify-content: flex-end;
  width: 220px;
  position: relative;
}

.TariffSelectionBar-removeAllButtonContainer {
  text-align: right;
  flex-basis: 100%;
}

.TariffSelectionBar-removeAllButton {
  color: @c24-color-tariff-selection-bar-remove-all-button;
}

.TariffSelectionBar-compareButton {
  position: absolute;
  left: 0;
  bottom: 0;
  border: none !important;

  @media @c24-wide {
    font-size: 15px;
  }
}

.TariffSelectionBar-selectionText {
  display: none;

  @media @c24-s-wide {
    flex: 0 0 230px;

    display: block;
    color: @c24-color-white;
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 5px;
    width: 208px;
    min-width: 208px;
  }

  @media @c24-wide {
    margin-right: 15px;
  }
}

.TariffSelectionBar-emptyText {
  font-size: 12px;
  color: @c24-color-white;
  margin-top: 10px;
}
