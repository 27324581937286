.AppointmentBookingMessage-container {
  display: flex;
  flex-direction: column;
}

.AppointmentBookingMessage-header {
  margin-top: 15px;
}

.AppointmentBookingMessage-input {
  margin-top: 15px;
}

.AppointmentBookingMessage-tintedInput.TextAreaInput-field {
  color: @c24-color-grey;
}

.AppointmentBookingMessage-cta {
  margin-top: 10px;
}

.AppointmentBookingMessage-savedMessage {
  display: flex;
  margin-top: 15px;
}

.AppointmentBookingMessage-savedIcon {
  width: 15px;
  min-width: 15px;
  height: 12px;
  fill: @c24-color-dark-green;
  margin-right: 15px;
  margin-top: 4px;
}
