.OnboardingContributionPage {
  display: flex;
  flex-direction: column;
}

.OnboardingContributionPage-formContainer {
  width: 100%;
}

.OnboardingContribution-ctaContainer {
  display: block;
  padding: 15px;
}

.OnboardingContribution-backButton {
  font-size: 13px;
  font-weight: bold;
}

.OnboardingContribution-nextPageButton {
  height: 50px;
  font-size: 20px;
}
