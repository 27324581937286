.OnboardingContributionInsuranceStartRiester-boosterBox {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  background: @c24-color-dark-green-alpha-15;
  color: @c24-color-black;
  border-left: 3px solid @c24-color-dark-green;
  margin-bottom: 20px;
}

.OnboardingContributionInsuranceStartRiester-noOneTimePaymentBoosterBox {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  background: @c24-color-dark-green-alpha-15;
  color: @c24-color-black;
  border-left: 3px solid @c24-color-dark-green;
  margin-bottom: 20px;

  .OnboardingContributionInsuranceStartRiester-boosterCheckIconSvg {
    margin-right: 15px;
    svg {
      width: 25px;
      height: 20px;
      fill: @c24-color-dark-green;
    }
  }
}

.OnboardingContributionInsuranceStartRiester-boosterBoxIcon {
  min-width: 25px;
  height: 25px;
  margin-right: 10px;
}
