@import '../../styles/variables/colors';

.CseTeasers {
  background-color: @c24-color-white-background;
  padding: 10px 0;
}

.CseTeasers-headline {
  font-size: 18px;
  font-weight: 400;
  color: @c24-color-malaga-grey;
  margin: 0 10px 10px 10px;
}

.CseTeasers-container {
  background-color: #fff;
  font-size: 11px;
  line-height: 16px;
  color: #666;

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }
}
