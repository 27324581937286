.AppointmentBookingToolTimeSlots-container {
  border-radius: 3px;
  background-color: @c24-color-soft-orange;
  padding: 11px 8px 13px 8px;
  margin-top: 10px;
}

.AppointmentBookingToolTimeSlots-title {
  font-size: 16px;
  margin-bottom: 6px;
  padding-left: 5px;
}

.AppointmentBookingToolTimeSlots-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, 78px);
  justify-content: space-between;
}

.AppointmentBookingToolTimeSlots-button {
  background-color: @c24-color-white;
  border-radius: 3px;
  border: 1px solid @c24-color-nobel-grey;
  width: 70px;
  height: 40px;
  padding-top: 8px;
  margin: 5px;
  text-align: center;

  &.is-selected {
    border: none;
    background-color: @c24-color-dark-blue;
    color: @c24-color-white;
  }

  &.is-disabled {
    background-color: @c24-color-background-grey;
    border: @c24-border-divider-grey;
    color: @c24-color-divider-grey;

    &:hover {
      border: @c24-border-divider-grey;
    }
  }
}

.AppointmentBookingToolTimeSlots-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
}

.AppointmentBookingToolTimeSlots-label {
  font-size: 14px;
}
