.ResultZeroHeader {
  background-color: @c24-result-zero-header;
  padding-right: 6px;
  min-height: 26px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  border-radius: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;

  .Popup-content {
    color: @c24-color-popup-content;
    font-size: 16px;
  }

  .Tooltip-headline {
    color: @c24-color-black;
    margin-bottom: 15px;
  }
}

.ResultZeroHeader-text {
  font-size: 14px;
  font-weight: 600;
  color: @c24-result-zero-header-text;
  line-height: 1.2;
}

.ResultZeroHeader-infoContainer {
  margin-left: 5px;
  height: 14px;
  width: 14px;
  min-height: 14px;
  min-width: 14px;
  pointer-events: auto;
  cursor: help;
  fill: @c24-result-zero-header-icon;
}

.ResultZeroHeader-tooltip {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.ResultZeroHeader-accentWord {
  color: @c24-result-zero-header-text;
}
