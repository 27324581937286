.ResultSustainabilityFilterInfoBox {
  display: flex;
  background-color: @c24-color-nobel-green;
  padding: 12px 9px 12px 9px;
  color: @c24-color-black;
  align-items: flex-start;
  font-size: 14px;
  margin: 5px 10px 12px;
  border-radius: 3px;
}

.ResultSustainabilityFilterInfoBox-leaf {
  fill: @c24-color-green;
  width: 22px;
  height: 22px;
  margin-right: 16px;
}
