@import '../../../styles/variables/colors';

.HandelsblattLogo-img {
  cursor: pointer;
  pointer-events: auto;
  height: 135px;

  @media @is-IE11 {
    width: 78px;
  }

  &.is-footer {
    width: 70px;
    height: 120px;
    margin-right: 10px;
  }
}

.HandelsblattLogo-tooltipContent {
  color: @c24-color-popup-content;
}

.HandelsblattLogo-header {
  font-weight: 700;
  color: @c24-color-dark-grey;
  margin-bottom: 15px;
}

.HandelsblattLogo-tooltip .Tooltip {
  width: 475px;
}
