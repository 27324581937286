.BackToTopButton {
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1;

  &.is-comparison-mode {
    bottom: ~'calc(env(safe-area-inset-bottom) + var(--safe-area-tap-bar-not-app) + 20px)';
  }
}
