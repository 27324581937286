.SofortTip {
  padding: 18px;
  padding-top: 12px;
  padding-bottom: 22px;
  background-color: @c24-color-white;
  margin-top: 8px;
  border-left: 4px solid @c24-color-dark-green;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.SofortTip-headerContainer {
  margin-bottom: 6px;
}

.SofortTip-booster {
  display: flex;
  align-items: center;
  width: 65px;
  justify-content: space-between;
}

.SofortTip-icon {
  display: flex;
  align-items: center;
  margin-left: -2px;
  svg {
    width: 18px;
    height: 14px;
    path {
      fill: @c24-color-dark-green;
    }
  }
}

.SofortTip-header {
  font-size: 16px;
  margin-top: 4px;
  padding-right: 5px;
  font-weight: bold;
}

.SofortTip-content {
  margin-bottom: 10px;
  padding-right: 5px;
}

.SofortTip-text {
  font-size: 16px;
}

.SofortTip-button.Button--secondary {
  border: 1px solid @c24-color-dark-blue;
  color: @c24-color-dark-blue;
  font-weight: 300;
  box-shadow: none;
}

.SofortTip-buttonText {
  font-size: 20px;
}
